/* @import url("https://fonts.cdnfonts.com/css/acari-sans-neue?styles=44624"); */
.JoinTELV {
  text-align: center;
}
.JoinTELV h1 {
  font-weight: 700;
  font-size: 64px;
  line-height: 72px;
  text-align: center;
  color: #ffffff;
}
.noneSecond.row .col-md-4.col-4:last-child {
  display: none !important;
}
.JoinTELV h2 {
  font-weight: 400;
  font-size: 45px;
  letter-spacing: 4.4px;
  background: linear-gradient(
    92.1deg,
    #e34038 -12.35%,
    #e34038 30.1%,
    #e34038 104.64%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
  text-align: center;
  margin: 25px auto;
}
.JoinTELV .count {
  text-align: center;
  background: #3b3b3b;
  border-radius: 24.4912px;
  padding: 5rem 20px 2rem;
}
.JoinTELV .count h1 {
  font-weight: 500;
  font-size: 120px;
  line-height: 120%;
  text-align: center;
  /* font-family: "Uniform Regular", sans-serif; */
  font-family: "Acari Sans Neue", sans-serif;
}
.JoinTELV .count p {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  text-transform: capitalize;
}
.JoinTELV button.custom-primary.btn.btn-primary {
  padding: 16px 5rem;
  margin-top: 30px;
}
.min-height-100per {
  min-height: 100vh;
  padding: 3rem 0px;
}
@media (max-width: 991.98px) {
  .JoinTELV .min-height-100per {
    min-height: auto;
    padding: 3rem 0px;
  }
  .JoinTELV .count p {
    font-size: 18px;
    line-height: 18px;
  }
  .JoinTELV .count h1 {
    font-weight: 500;
    font-size: 60px;
    line-height: 146%;
    text-align: center;
  }
  .JoinTELV h2 {
    font-size: 24px;
    letter-spacing: 6.4px;
  }
  .JoinTELV h1 {
    font-size: 45px;
    line-height: 55px;
    letter-spacing: 1px;
  }
  .JoinTELV .count {
    text-align: center;
    background: #3b3b3b;
    border-radius: 12.4912px;
    padding: 2rem 10px 1.5rem;
  }
  .JoinTELV .col-md-4.col-4 {
    padding: 0 5px;
  }
  .JoinTELV button.custom-primary.btn.btn-primary {
    width: 100%;
  }
}
