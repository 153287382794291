img.chart3 {
  width: 100%;
}
@media (max-width: 768px) {
  .Release.roadmap-release {
    padding: 2rem;
    border: 1px solid #e3403836;
    border-radius: 15px;
  }
}
