.Release {
  padding-top: 7rem;
}
img.chart {
  margin-left: -2rem;
}
.py-2rem {
  padding: 2rem 0;
}
img.chart2 {
  width: 100%;
  text-align: center;
}
.Release #chart {
  color: #ccc;
}
.apexcharts-legend-text {
  position: relative;
  color: #767676 !important;
  fill: #545454 !important;
}
.apexcharts-text tspan {
  fill: #a3a3a3 !important;
  /* font-family: "Acari Sans Neue", sans-serif !important; */
}
.Release #chart .apexcharts-yaxis .apexcharts-text tspan {
  fill: #ffffff !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 52px;
  text-align: right;
  letter-spacing: 0.437378px;
}
.Release #chart .apexcharts-tooltip.apexcharts-theme-light {
  border: 1px solid #37333d;
  background: rgb(23 22 30);
}
.Release #chart .apexcharts-tooltip {
  box-shadow: 2px 2px 6px -4px #e3403800;
}
.Release
  #chart
  .apexcharts-tooltip.apexcharts-theme-light
  .apexcharts-tooltip-title {
  background: #201f28;
  border-bottom: 1px solid #dddddd0f;
}
.Release #chart foreignObject {
  background: linear-gradient(
    119deg,
    rgb(47 141 162 / 0%) 0%,
    rgb(156 68 245 / 0%) 39%,
    rgb(124 12 135 / 13%) 69%,
    rgb(23 22 30) 100%
  );
}
.Release #chart .apexcharts-menu {
  background: #2b2b2b;
  border: 1px solid #dddddd1a;
  min-width: 130px;
}
.Release #chart .apexcharts-theme-light .apexcharts-menu-item:hover {
  background: #eeeeee14;
}
.Release1 tspan {
  fill: #fff;
}
.Release1 polyline {
  stroke: #fff;
  opacity: 1;
}
g[fill="#ff9901"] {
  scale: 1.09;
}
g[fill="#ea4335"] {
  outline: none;
  scale: 1.09;
  box-shadow: 10px 0px 77px 20px rgb(255 255 255);
  stroke: #4e110c1f;
  stroke-width: 3px;
}
g[fill="#af52de"] {
  /* scale: 1.05; */
  box-shadow: 10px 0px 77px 20px rgb(255 255 255);
  stroke: #b900b930;
  stroke-width: 3px;
}
g[fill="#267ba3"] {
  scale: 1.09;
}
g[fill="#4285f4"] {
  /* scale: 0.8; */
}
g[aria-label="Legend"] {
  display: none;
}
/* g[fill="#4285f4"]:hover {
  scale: 1;
} */
g[fill="#34a853"] {
  /* scale: 0.8; */
  stroke: #0000001a;
  stroke-width: 3px;
}
g[fill="#46bdc6"] {
  /* scale: 0.9; */
  box-shadow: 10px 0px 77px 20px rgb(255 255 255);
  stroke: #4e110c1f;
  stroke-width: 3px;
}
g[fill="#f07b72"] {
  /* scale: 0.9; */
  box-shadow: 10px 0px 77px 20px rgb(255 255 255);
  stroke: #4e110c1f;
  stroke-width: 3px;
}
g[fill="#af52de"] {
  scale: 1.09;
}
img.chart-center {
  position: absolute;
  z-index: 1;
  top: 31%;
  left: 42%;
  display: none;
}
g[opacity="0.4"],
g[opacity="0.3"] {
  display: none;
}
@media (max-width: 991.98px) {
  g[aria-label="Legend"] {
    display: block;
  }
  .Release1 g[aria-label="Series"] tspan {
    display: none;
  }
  .Release1 g[aria-label="Series"] polyline {
    display: none;
  }
  .py-2rem {
    padding: 0rem 0;
  }
}
