.button-download-app {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #eceaef;
  border-radius: 4px;
  margin-bottom: 16px;
  width: 192px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 64px;
}
.dialog-controls {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
}
.RFS-StepMain .RFS-StepButton.active {
  background-color: #3a3a3a;
}
.RFS-StepMain .RFS-StepButton.completed {
  background-color: #3a3a3a;
}
.RFS-StepMain .RFS-StepButton.active span {
  color: #fff;
}
.RFS-StepMain .RFS-StepButton.completed span {
  color: #fff;
}
.RFS-StepMain .RFS-StepButton {
  background-color: rgb(255 255 255);
  border: 1px solid #e0e0e0;
}
.RFS-StepMain .RFS-StepButton span {
  color: rgb(58 58 58);
}
.RFS-StepMain span.RFS-Label {
  font-size: 13px;
  margin-top: 8px;
}
.title-section {
  font-size: 18px;
  color: #1c273c;
  font-weight: 600;
  margin-bottom: 5px;
}
.RFS-StepContainer {
  padding-left: 5px;
  padding-right: 5px;
}
.scan-qr {
  height: 96px;
  width: 96px;
}

input.code-input:-webkit-autofill,
input.code-input:-webkit-autofill:focus,
input.code-input:-webkit-autofill:hover {
  border: 1px solid #0b4a8f;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0 1000px #001b7a inset !important;
  transition: background-color 5000s ease-in-out 0s;
}
.wizard > .steps a,
.wizard > .steps a:active,
.wizard > .steps a:hover {
  align-items: center;
  color: #1c273c;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  outline: 0;
}
.steps a,
.steps div {
  text-decoration: unset !important;
}
a {
  text-decoration: unset;
}
