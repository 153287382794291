.televerse-world {
  height: 100vh;
}

.map-img {
  display: none;
}

.televerse-world-head {
  font-family: "Sora";
  font-weight: 700;
  font-size: 64px;
  color: #ffffff;
  line-height: 72px;
  margin-top: 6rem;
  word-spacing: 100vw;
}

.televerse-world-description {
  font-family: "Sora";
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #a3a3a3;
  max-width: 417.03px;
  margin-top: 1.5rem;
}
.televerseworld {
  background-image: url(../../../../assets/home/Token.svg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-color: #2b2b2b;
  min-height: 100vh;
  height: 100vh;
}

@media (max-width: 992px) {
  .map-img {
    display: flex;
  }
  .televerseworld {
    background: unset;
  }
  .televerse-world-head {
    font-size: 40px;
    line-height: 48px;
    margin-top: 3rem;
    word-spacing: 0;
  }
  .televerse-world-description {
    font-size: 16px;
    line-height: 24px;
    max-width: 100%;
    margin-top: 1rem;
    width: 100%;
  }
  .televerse-world-img {
    display: flex;
    justify-content: center;
  }
}
