.admin .sidebar .nav-item.dropdown .dropdown-toggle::after,
.admin .topbar .nav-item.dropdown .dropdown-toggle::after {
  width: 1rem;
  text-align: center;
  float: right;
  vertical-align: 0;
  border: 0;
  font-weight: 900;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
}

.admin .sidebar .nav-item.dropdown.show .dropdown-toggle::after,
.admin .topbar .nav-item.dropdown.show .dropdown-toggle::after {
  content: "\f107";
}

.admin .sidebar .nav-item .nav-link,
.admin .topbar .nav-item .nav-link {
  position: relative;
}

.admin .sidebar .nav-item .nav-link .badge-counter,
.admin .topbar .nav-item .nav-link .badge-counter {
  position: absolute;
  transform: scale(0.7);
  transform-origin: top right;
  right: 0.25rem;
  margin-top: -0.25rem;
}

.admin .sidebar .nav-item .nav-link .img-profile,
.admin .topbar .nav-item .nav-link .img-profile {
  height: 2rem;
  width: 2rem;
}

.admin .topbar {
  height: 4.375rem;
}
nav.navbar.admin .topbar {
  height: 4.375rem;
}
.admin .topbar #sidebarToggleTop {
  height: 2.5rem;
  width: 2.5rem;
}

.admin .topbar #sidebarToggleTop:hover {
  background-color: #eaecf4;
}

.admin .topbar #sidebarToggleTop:active {
  background-color: #dddfeb;
}

.admin .topbar .navbar-search {
  width: 25rem;
}

.admin .topbar .navbar-search input {
  font-size: 0.85rem;
  height: auto;
}

.admin .topbar .topbar-divider {
  width: 0;
  border-right: 1px solid #e3e6f0;
  height: calc(4.375rem - 2rem);
  margin: auto 1rem;
}

.admin .topbar .nav-item .nav-link {
  height: 4.375rem;
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
}

.admin .topbar .nav-item .nav-link:focus {
  outline: none;
}

.admin .topbar .nav-item:focus {
  outline: none;
}

.admin .topbar .dropdown {
  position: static;
}

.admin .topbar .dropdown .dropdown-menu {
  width: calc(100% - 1.5rem);
  right: 0.75rem;
}

.admin .topbar .dropdown-list {
  padding: 0;
  border: none;
  overflow: hidden;
}

.admin .topbar .dropdown-list .dropdown-header {
  background-color: #4e73df;
  border: 1px solid #4e73df;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #fff;
}

.admin .topbar .dropdown-list .dropdown-item {
  white-space: normal;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-left: 1px solid #e3e6f0;
  border-right: 1px solid #e3e6f0;
  border-bottom: 1px solid #e3e6f0;
  line-height: 1.3rem;
}

.admin .topbar .dropdown-list .dropdown-item .dropdown-list-image {
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
}

.admin .topbar .dropdown-list .dropdown-item .dropdown-list-image img {
  height: 2.5rem;
  width: 2.5rem;
}

.admin
  .topbar
  .dropdown-list
  .dropdown-item
  .dropdown-list-image
  .status-indicator {
  background-color: #eaecf4;
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  border: 0.125rem solid #fff;
}

.admin .topbar .dropdown-list .dropdown-item .text-truncate {
  max-width: 10rem;
}

.admin .topbar .dropdown-list .dropdown-item:active {
  background-color: #eaecf4;
  color: #3a3b45;
}

@media (min-width: 576px) {
  .admin .topbar .dropdown {
    position: relative;
  }
  .admin .topbar .dropdown .dropdown-menu {
    width: auto;
    right: 0.75rem;
    left: auto;
  }
  .admin .topbar .dropdown-list {
    width: 20rem !important;
  }
  .admin .topbar .dropdown-list .dropdown-item .text-truncate {
    max-width: 13.375rem;
  }
}

.topbar.navbar-dark .navbar-nav .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}

.topbar.navbar-dark .navbar-nav .nav-item .nav-link:hover {
  color: #fff;
}

.topbar.navbar-dark .navbar-nav .nav-item .nav-link:active {
  color: #fff;
}

.topbar.navbar-light .navbar-nav .nav-item .nav-link {
  color: #858796;
}

.topbar.navbar-light .navbar-nav .nav-item .nav-link:hover {
  color: #b7b9cc;
}

.topbar.navbar-light .navbar-nav .nav-item .nav-link:active {
  color: #858796;
}

.admin .sidebar {
  width: 6.5rem;
  min-height: 100vh;
}

.admin .sidebar .nav-item {
  position: relative;
}

.admin .sidebar .nav-item:last-child {
  margin-bottom: 1rem;
}

.admin .sidebar .nav-item .nav-link {
  text-align: center;
  padding: 0.75rem 1rem;
  width: 6.5rem;
}

.admin .sidebar .nav-item .nav-link span {
  font-size: 0.65rem;
  display: block;
}

.admin .sidebar .nav-item .active.nav-link {
  background-color: #161620;
  color: #fff;
}
.admin .sidebar .nav-item .active.nav-link i {
  color: #fff;
}
.admin .sidebar .nav-item:hover .nav-link {
  background-color: #161620;
}
.admin .sidebar .nav-item .collapse {
  position: absolute;
  left: calc(6.5rem + 1.5rem / 2);
  z-index: 1;
  top: 2px;
}

.admin .sidebar .nav-item .collapse .collapse-inner {
  border-radius: 0.35rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}

.admin .sidebar .nav-item .collapsing {
  display: none;
  transition: none;
}

.admin .sidebar .nav-item .collapse .collapse-inner,
.admin .sidebar .nav-item .collapsing .collapse-inner {
  padding: 0.5rem 0;
  min-width: 10rem;
  font-size: 0.85rem;
  margin: 0 0 1rem 0;
}

.admin .sidebar .nav-item .collapse .collapse-inner .collapse-header,
.admin .sidebar .nav-item .collapsing .collapse-inner .collapse-header {
  margin: 0;
  white-space: nowrap;
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 0.65rem;
  color: #b7b9cc;
}

.admin .sidebar .nav-item .collapse .collapse-inner .collapse-item,
.admin .sidebar .nav-item .collapsing .collapse-inner .collapse-item {
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  display: block;
  color: #3a3b45;
  text-decoration: none;
  border-radius: 0.35rem;
  white-space: nowrap;
}

.admin .sidebar .nav-item .collapse .collapse-inner .collapse-item:hover,
.admin .sidebar .nav-item .collapsing .collapse-inner .collapse-item:hover {
  background-color: #eaecf4;
}

.admin .sidebar .nav-item .collapse .collapse-inner .collapse-item:active,
.admin .sidebar .nav-item .collapsing .collapse-inner .collapse-item:active {
  background-color: #dddfeb;
}

.admin .sidebar .nav-item .collapse .collapse-inner .collapse-item.active,
.admin .sidebar .nav-item .collapsing .collapse-inner .collapse-item.active {
  color: #4e73df;
  font-weight: 700;
}

.admin .sidebar #sidebarToggle {
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
  cursor: pointer;
}

.admin .sidebar #sidebarToggle::after {
  font-weight: 900;
  content: "\f104";
  font-family: "Font Awesome 5 Free";
  margin-right: 0.1rem;
}

.admin .sidebar #sidebarToggle:hover {
  text-decoration: none;
}

.admin .sidebar #sidebarToggle:focus {
  outline: none;
}

.sidebar.toggled {
  width: 0 !important;
  overflow: hidden;
}

.sidebar.toggled #sidebarToggle::after {
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  margin-left: 0.25rem;
}

.sidebar.toggled .sidebar-card {
  display: none;
}

.admin .sidebar .sidebar-brand {
  height: 4.375rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 800;
  padding: 1.5rem 1rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  z-index: 1;
}

.admin .sidebar .sidebar-brand .sidebar-brand-icon i {
  font-size: 2rem;
}

.admin .sidebar .sidebar-brand .sidebar-brand-text {
  display: none;
}

.admin .sidebar hr.sidebar-divider {
  margin: 0 1rem 1rem;
}

.admin .sidebar .sidebar-heading {
  text-align: center;
  padding: 0 25px;
  /* font-weight: 800; */
  font-size: 14px;
}
.admin .shadow {
  box-shadow: 0px 10px 30px 0px rgb(82 63 105 / 8%) !important;
}
.admin .sidebar .sidebar-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.875rem;
  border-radius: 0.35rem;
  color: rgba(255, 255, 255, 0.8);
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.1);
}

.admin .sidebar .sidebar-card .sidebar-card-illustration {
  height: 3rem;
  display: block;
}

.admin .sidebar .sidebar-card .sidebar-card-title {
  font-weight: bold;
}

.admin .sidebar .sidebar-card p {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
}

@media (min-width: 768px) {
  .admin .sidebar {
    width: 16rem !important;
    background: #1e1e2d;
  }
  .admin .sidebar .nav-item .collapse {
    position: relative;
    left: 0;
    z-index: 1;
    top: 0;
    -webkit-animation: none;
    animation: none;
  }
  .admin .sidebar .nav-item .collapse .collapse-inner {
    border-radius: 0;
    box-shadow: none;
  }
  .admin .sidebar .nav-item .collapsing {
    display: block;
    transition: height 0.15s ease;
  }
  .admin .sidebar .nav-item .collapse,
  .admin .sidebar .nav-item .collapsing {
    margin: 0 1rem;
  }
  .admin .sidebar .nav-item .nav-link {
    display: block;
    width: 100%;
    text-align: left;
    padding: 9px 25px !important;
    width: 16rem;
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .admin .sidebar .nav-item .nav-link i {
    font-size: 18px;
    margin-right: 0.65rem;
    width: 26px;
    text-align: center;
  }
  .admin .sidebar .nav-item .nav-link span {
    font-size: 0.85rem;
    display: inline;
  }
  .admin .sidebar .nav-item .nav-link[data-toggle="collapse"]::after {
    width: 1rem;
    text-align: center;
    float: right;
    vertical-align: 0;
    border: 0;
    font-weight: 900;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
  }
  .admin .sidebar .nav-item .nav-link[data-toggle="collapse"].collapsed::after {
    content: "\f105";
  }
  .admin .sidebar .sidebar-brand .sidebar-brand-icon i {
    font-size: 2rem;
  }
  .admin .sidebar .sidebar-brand .sidebar-brand-text {
    display: inline;
  }
  .admin .sidebar .sidebar-heading {
    text-align: left;
  }
  .sidebar.toggled {
    overflow: visible;
    width: 6.5rem !important;
  }
  .sidebar.toggled .nav-item .collapse {
    position: absolute;
    left: calc(6.5rem + 1.5rem / 2);
    z-index: 1;
    top: 2px;
    -webkit-animation-name: growIn;
    animation-name: growIn;
    -webkit-animation-duration: 200ms;
    animation-duration: 200ms;
    -webkit-animation-timing-function: transform
        cubic-bezier(0.18, 1.25, 0.4, 1),
      opacity cubic-bezier(0, 1, 0.4, 1);
    animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1),
      opacity cubic-bezier(0, 1, 0.4, 1);
  }
  .sidebar.toggled .nav-item .collapse .collapse-inner {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    border-radius: 0.35rem;
  }
  .sidebar.toggled .nav-item .collapsing {
    display: none;
    transition: none;
  }
  .sidebar.toggled .nav-item .collapse,
  .sidebar.toggled .nav-item .collapsing {
    margin: 0;
  }
  .sidebar.toggled .nav-item:last-child {
    margin-bottom: 1rem;
  }
  .sidebar.toggled .nav-item .nav-link {
    text-align: center;
    padding: 0.75rem 1rem;
    width: 6.5rem;
  }
  .sidebar.toggled .nav-item .nav-link span {
    font-size: 0.65rem;
    display: block;
  }
  .sidebar.toggled .nav-item .nav-link i {
    margin-right: 0;
  }
  .sidebar.toggled .nav-item .nav-link[data-toggle="collapse"]::after {
    display: none;
  }
  .sidebar.toggled .sidebar-brand .sidebar-brand-icon i {
    font-size: 2rem;
  }
  .sidebar.toggled .sidebar-brand .sidebar-brand-text {
    display: none;
  }
  .sidebar.toggled .sidebar-heading {
    text-align: center;
  }
}

.sidebar-light .sidebar-brand {
  color: #6e707e;
}

.sidebar-light hr.sidebar-divider {
  border-top: 1px solid #eaecf4;
}

.sidebar-light .sidebar-heading {
  color: #b7b9cc;
}

.sidebar-light .nav-item .nav-link {
  color: #858796;
}

.sidebar-light .nav-item .nav-link i {
  color: #d1d3e2;
}

.sidebar-light .nav-item .nav-link:active,
.sidebar-light .nav-item .nav-link:focus,
.sidebar-light .nav-item .nav-link:hover {
  color: #6e707e;
}

.sidebar-light .nav-item .nav-link:active i,
.sidebar-light .nav-item .nav-link:focus i,
.sidebar-light .nav-item .nav-link:hover i {
  color: #6e707e;
}

.sidebar-light .nav-item .nav-link[data-toggle="collapse"]::after {
  color: #b7b9cc;
}

.sidebar-light .nav-item.active .nav-link {
  color: #6e707e;
}

.sidebar-light .nav-item.active .nav-link i {
  color: #6e707e;
}

.sidebar-light #sidebarToggle {
  background-color: #eaecf4;
}

.sidebar-light #sidebarToggle::after {
  color: #b7b9cc;
}

.sidebar-light #sidebarToggle:hover {
  background-color: #dddfeb;
}

.sidebar-dark .sidebar-brand {
  color: #fff;
}

.sidebar-dark hr.sidebar-divider {
  border-top: 1px solid rgb(255 255 255 / 34%);
}

.sidebar-dark .sidebar-heading {
  color: rgba(255, 255, 255, 0.4);
}

.sidebar-dark .nav-item .nav-link {
  color: #a2a3b7;
}

.sidebar-dark .nav-item .nav-link i {
  color: #494b74;
}

.sidebar-dark .nav-item .nav-link:active,
.sidebar-dark .nav-item .nav-link:focus,
.sidebar-dark .nav-item .nav-link:hover {
  color: #fff;
}

.sidebar-dark .nav-item .nav-link:active i,
.sidebar-dark .nav-item .nav-link:focus i,
.sidebar-dark .nav-item .nav-link:hover i {
  color: #fff;
}

.sidebar-dark .nav-item .nav-link[data-toggle="collapse"]::after {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark .nav-item.active .nav-link {
  color: #fff;
}

.sidebar-dark .nav-item.active .nav-link i {
  color: #fff;
}

.sidebar-dark #sidebarToggle {
  background-color: rgba(255, 255, 255, 0.2);
}

.sidebar-dark #sidebarToggle::after {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark #sidebarToggle:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.sidebar-dark.toggled #sidebarToggle::after {
  color: rgba(255, 255, 255, 0.5);
}
.ml-auto {
  margin-left: auto;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
