.bg-cover {
  background-image: url(../../assets/Cover.svg);
  background-repeat: no-repeat;
  overflow: auto;
  background-size: 100% 260px;
  background-position: 0 0px;
}
.btnCustome.btn.btn-outline-secondary {
  max-height: 48px;
  border: 3px solid rgb(119 126 144);
  border-radius: 42px;
  color: #ffffff;
  padding: 0.4rem 1rem;
}
img.icon-btn {
  margin-left: 15px;
  width: 20px;
  height: 20px;
}
img.icon-Vector {
  margin-left: 5px;
  width: 17px;
}
h5.nickName {
  font-size: 22px;
  color: #fff;
}
h6.nickName {
  font-size: 18px;
  font-weight: 600;
}
.editProfile label {
  font-weight: 300;
  font-size: 16px;
}
.fillter-tap button.btnCustome.btn.btn-outline-secondary {
  font-size: 12px;
  padding: 0.25rem 1.2rem 0.2rem;
  font-weight: 300;
  line-height: 1.6;
}
.fillter-tap {
  border-bottom: 1px solid #ffffff1f;
}
.ProfileCard .whole-card {
  background: #3b3b3b;
  -webkit-backdrop-filter: blur(30.0041px);
  backdrop-filter: blur(30.0041px);
  border-radius: 16px;
  width: auto;
  max-height: 440px;
  height: auto;
  border: 1px solid #5454548c;
}
.ProfileCard .card-body {
  padding: 1rem 0.5rem;
  zoom: 0.9;
}
.ProfileCard img.card-img-top {
  height: 203.9px;
  object-fit: cover;
  border-radius: 15px;
}
.ProfileCard .card-price {
  font-weight: 300;
}
.cardLeftProfile {
  background: #3b3b3b;
  border-radius: 16px;
}
.cardLeftProfile .image-input .image-input-wrapper {
  width: 140px;
  height: 140px;
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center center;
  background: linear-gradient(
    96.27deg,
    #115743 12.79%,
    #4eb093 50.13%,
    #115743 107.19%
  );
  border-radius: 128px;
}
.btn.btn-black {
  color: #3f4254;
  background-color: #000;
  border-color: #000;
}
.imageCardLeftProfile .btn.btn-black {
  border-color: #fff;
}
.imageCardLeftProfile .btn.btn-black:hover {
  background: #eeeeee0d;
}
.cardLeftProfile .image-input [data-action="change"] {
  right: 15px;
  top: 25px;
}
p.addressLable {
  color: #ced3df;
  font-size: 14px;
}
p.addressLable i {
  float: right;
}
p.descriptionLable {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ced3df;
  word-break: break-word;
}
a.hrefLable {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #fcfcfd;
}
a.hrefLable i {
  color: #ced3df;
  margin-right: 5px;
}
button.btnFollow {
  padding: 0.5rem 1rem;
  font-size: 14px;
  height: 40px;
}
img.upload {
  margin-left: -1px;
}
button.btnUpload {
  background: #eee0;
  width: 40px;
  height: 40px;
  padding: 0px;
  border-radius: 50%;
  margin-left: 7px;
  line-height: 1;
  text-align: center;
  border: 2px solid #545454;
}
button.btnUpload:hover {
  background: #2b2b2b;
}
ul.shareUl {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding: 7px 0;
  margin-bottom: 0;
}
ul.shareUl img.twetterLink {
  width: 22px;
  height: 22px;
}
ul.shareUl li {
  display: block;
  padding-left: 3px;
  padding-right: 3px;
}
hr {
  margin: 0.5rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.1;
}
a.copyBtn {
  float: right;
}
img.global {
  width: 20px;
  margin-right: 3px;
}
p.since {
  color: #ced3df;
  font-size: 12px;
}
.w-14 {
  width: 14px;
}
.fillter-tap button.btnCustome.activeBtn.btn.btn-outline-secondary {
  background: #fff;
  border: 3px solid #fff;
  color: #545454;
  font-weight: 600;
}
.FollowModal .modal-content {
  background: #3b3b3b;
  border-radius: 30px;
  padding: 1.5rem 3rem !important;
}
.modal-header a.copyBtn {
  float: right;
  margin-left: 16px;
  margin-top: -4px;
}
.modal .nav.nav-tabs {
  border-color: #545454;
}
.modal .nav-tabs .nav-link {
  color: #fcfcfd;
}
.modal .nav-tabs .nav-link.active {
  color: #fcfcfd;
  font-weight: 600;
}
img.closeModal {
  width: 20px;
  margin-top: -2px;
}
button.btn.btn-outline-secondary.closeModal {
  border-radius: 50%;
  width: 38px;
  height: 38px;
  padding: 0px;
}
.modal .tab-content {
  padding-top: 2rem;
}
.follow-person {
  display: flex;
  border-bottom: 1px solid #545454;
  padding: 15px 0;
}
.follow-person .btnCustome {
  margin-left: auto;
  margin-right: 0;
}
.follow-person button.btnCustome.btn.btn-outline-secondary {
  max-height: 40px;
  font-size: 14px;
}
.follow-person .card-owner {
  color: #ffffff;
}
.follow-person .card-owner-name {
  font-size: 14px;
  color: #ced3df;
}
.tab-pane .row .col-xl-12.col-md-12:last-child .follow-person {
  border-bottom: 0;
}
.descriptionEditLable {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ced3df;
}
.descriptionEditLable span {
  color: #fff;
}
.border-bottom {
  border-color: #545454 !important;
}
.avatarImage .image-input .image-input-wrapper {
  width: 140px;
  height: 140px;
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center center;
  background: linear-gradient(
    96.27deg,
    #115743 12.79%,
    #4eb093 50.13%,
    #115743 107.19%
  );
  border-radius: 128px;
}
.image-input .btnCustome.btn.btn-outline-secondary[data-action="change"] {
  position: relative;
  right: auto;
  left: auto;
  top: auto;
  bottom: auto;
  margin-bottom: 0;
}
.image-input
  .btnCustome.btn.btn-outline-secondary[data-action="change"]:active {
  background-color: #6c757d;
  border: 3px solid #6c757d;
}
img.profile_picture {
  width: 41px;
  height: 41px;
  border-radius: 50%;
  background: #5cb937;
  max-width: 41px;
  max-height: 41px;
  object-fit: cover;
}
img.twetterLinkInput {
  width: 20px;
  margin-right: 10px;
}
.TrendingSection .viewItem {
  display: none;
}
.TrendingSection .viewItem:nth-child(n + 1):nth-child(-n + 10) {
  display: block;
}
p.descriptionLable span {
  color: #e34038;
  cursor: pointer;
}
