.heroSmImage {
  display: none;
  width: 100%;
  aspect-ratio: 1/1.1;
  max-height: 400px;
  object-fit: cover;
  border-radius: 56.8125px;
}

@media (max-width: 768px) {
  .flexMdColumn {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .hero-section {
    background: unset;
  }

  .heroSmImage {
    display: block;
  }
}
