.ds-text h1 {
  font-family: "Sora";
  font-size: 28px;
  line-height: 44px;
  letter-spacing: 0.105em;
  text-transform: uppercase;
  color: #fcfcfd;
  text-align: center;
}

.ds-text h3 {
  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  text-align: center;

  letter-spacing: 0.15em;
  text-transform: uppercase;

  background: linear-gradient(
    270deg,
    #5cb937 17.13%,
    #0fb3ff 56.62%,
    #9e44f6 82.12%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.start-soon-text {
  margin-bottom: 30px;
}

.ds-closed-bg {
  background: url(../../../assets/ds-closed.png) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: 68% 50% !important;
  margin: 4rem 0;
  border-radius: 20px;
}

@media (max-width: 991.98px) {
  .ds-closed-bg {
    background: url(../../../assets/mob-ds-closed.png) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: 50% 83% !important;
  }
}

@media (max-width: 767.98px) {
  .ds-text {
    text-align: center;
  }

  .ds-text h1 {
    font-size: 20px;
    line-height: 28px;
  }

  .ds-text h3 {
    font-size: 32px;
  }
}
