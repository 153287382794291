.footer p.text-footer {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #a3a3a3;
  margin-bottom: 2rem;
}
.footer p span {
  color: #ff4a41;
  cursor: pointer;
}
ul.social-ul-footer {
  display: flex;
  padding-left: 0;
  text-align: center;
  margin: 0 auto;
}
ul.social-ul-footer {
  display: flex;
  margin: 0 auto;
}
ul.social-ul-footer li a.linkedin-team {
  width: 40px;
  height: 40px;
  display: block;
  line-height: 40px;
  border-radius: 50%;
  color: #fff;
  background: #202020;
}
ul.social-ul-footer li a.linkedin-team:hover {
  background: #fa6109;
}
ul.social-ul-footer li {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.footer .title-div img {
  margin-bottom: 2rem;
  height: 30px;
}
.card-info-footer {
  display: flex;
  align-items: center;
}
.text-info p.title-info {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #fff;
  margin-bottom: 0px;
}
.text-info p.feedback-info {
  /* font-weight: 600; */
  font-size: 14px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-bottom: 0;
}
.text-info p.feedback-info a {
  color: #fff;
  margin-left: 10px;
}
.card-info-footer img {
  margin-right: 10px;
  height: 30px;
  width: 30px;
}
.rights p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  color: #ffffff;
  margin-bottom: 0;
}
.content-footer {
  padding-bottom: 4rem;
}
.footer-information {
  padding-top: 1rem;
  padding-bottom: 0rem;
}
.viewMain .modal-content {
  background: #3b3b3b;
  padding: 3rem;
}
.viewMain .modal-content .modal-header {
  border-bottom: 0;
}
.viewMain.modal .modal-header .modal-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 125%;
  /* text-transform: uppercase; */
  color: #fcfcfd !important;
}
.textVedio p {
  font-size: 14px;
  margin-bottom: 10px;
}
.viewMain .modal-body {
  color: #ffffffd4;
  font-size: 16px;
}
.viewMain .modal-content .modal-header button.btn-close {
  background-color: #eee;
  padding: 10px;
  position: absolute;
  right: 0px;
  top: 0px;
}
.linkHere {
  color: #f8780c;
}
.footer {
  /* background-image: url(../../../assets/layout/footer.svg); */
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  /* background-color: #2b2b2b; */
  /* padding-top: 12rem; */
  margin-top: 0rem;
}
@media (max-width: 991.98px) {
  .footer {
    /* padding-top: 5rem; */
    margin-top: -1rem;
    background-size: contain;
  }
  .footer p.text-footer {
    text-align: justify;
  }
  .content-footer {
    padding-bottom: 0rem;
  }
}
@media (min-width: 1600px) {
  .footer {
    /* padding-top: 18rem; */
    margin-top: -3rem;
    /* margin-top: -18rem; */
  }
}
