.svgStyle {
  border: 1px solid #333;
  cursor: grab;
}

.hexagon:hover {
  fill: #e34038 !important;
}
.hexagonImg:hover {
  opacity: 0.7;
}
.parent1 {
  fill: #30263b00;
}
.MapContainer {
  background-image: url(../../../../assets/HexaPattern.png) !important;
  background-repeat: repeat;
  background-position: 50%;
  background-size: 150px !important;
  height: 600px;
  background: #272727;
  margin-top: -100px;
  border-radius: 8px;
  overflow: hidden;
  overscroll-behavior: contain;
  /* max-width: 1360px !important;
  margin-right: auto;
  margin-left: auto; */
}
.LandProfileMap .MapContainer {
  margin-top: 0px;
  margin-bottom: 30px;
  height: 400px;
}
.mapLinks .FillterBySort .sortDiv {
  margin-right: 15px;
  background: rgba(34, 33, 43, 0.8);
  border-radius: 8px;
  padding: 10px;
}
.mapLinks .FillterBySort {
  margin-right: 15px;
}
.HexaWithImage {
  /* stroke: #442a62; */
}
.HexaWithImage:hover {
  opacity: 0.7;
}
.IdSelected {
  stroke: rgb(255 72 72);
  stroke-width: 1px;
}
/* // Medium devices (tablets, less than 992px) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* // X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
}
