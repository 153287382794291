.add-value-title {
  margin-bottom: 60px;
}

.value-col {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.value-list {
  padding-left: 0;
}

.value-title {
  font-family: "Sora";
  font-weight: 700;
  font-size: 64px;
  line-height: 72px;
  color: #ffffff;
}

.value-list-item-title {
  font-family: "Sora";
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  background: linear-gradient(
    90deg,
    #e34038 -16.36%,
    #e34038 25.1%,
    #9e44f6 97.88%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.value-list-item-text {
  width: 100%;
  font-family: "Sora";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #a3a3a3;
}

.adjut-value-img {
  display: flex;
  align-items: center;
}

.mob-add-val {
  display: none !important;
}
img.list-icon {
  display: block;
  height: 20px;
  width: auto;
  margin-top: 0px;
  z-index: 1;
}
li.value-list-item {
  display: flex;
}
.value-list-item .text-part {
  border-left: 2px solid #3b3741;
  padding-left: 29px;
  margin-left: -10px;
  margin-top: 6px;
  padding-bottom: 20px;
}
.value-list-item:last-child .text-part {
  border-left: 0;
}
.value-list-item .text-part h3.value-list-item-title {
  margin-top: -15px;
}
.elips {
  background-image: url(../../../../assets/img/Ellipse.svg);
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  background-color: #2b2b2b;
}

@media (max-width: 992px) {
  .value-list-item-text {
    width: 100%;
  }
  .add-value-title {
    display: none;
  }
  .mob-add-val {
    display: flex !important;
  }
  .value-list-item .text-part {
    border-left: 0;
  }
  .elips {
    background: unset;
  }
}

@media (max-width: 768px) {
  .value-title {
    font-size: 48px;
    line-height: 56px;
  }
}
