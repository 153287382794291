
.text-of-slider h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 15px;
}
.text-of-slider span, .text-of-slider th {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ff4a41;
}
.text-of-slider p, .text-of-slider td{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #969696;
  margin-top: 10px;
}
ul.social-ul {
  padding-left: 0;
  display: flex;
}
ul.social-ul li {
  display: block;
  width: 36px;
  height: 36px;
  text-align: center;
  margin-right: 5px;
  border: 4px solid rgb(143 143 143);
  border-radius: 50%;
  line-height: 30px;
  color: rgb(143 143 143);
}
ul.social-ul li i {
  color: rgb(143 143 143);
}
ul.social-ul li:hover {
  border: 4px solid #e34038;
}
ul.social-ul li:hover i {
  color: #ff4a41;
}
.Team h1 {
  font-weight: 700;
  font-size: 64px;
  line-height: 72px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 3rem;
}
.item:hover {
  background: rgba(78, 78, 97, 0.05);
  cursor: pointer;
}

.itemslide {
  border: 0px solid #fff;
  z-index: 2;
  background: url(../../../assets/home&token/bg.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: block;
  padding: 25px;
}
.slider-theme .slick-list {
  padding-top: 7rem;
}
.itemslide img {
  display: block;
  margin-bottom: -40px;
  margin-top: -130px;
}
.itemslide .text-of-slider p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #969696;
  margin-top: 10px;
}
.itemslide .text-of-slider span {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.itemslide .text-of-slider h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 10px;
}
.slider-theme .slick-prev,
.slider-theme .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: -4rem;
}
.slider-theme .slick-next {
  right: 13px;
}
.slider-theme .slick-prev {
  left: auto;
  right: 55px;
}
button.slick-arrow.slick-next {
  display: block;
  width: 36px;
  height: 36px;
  transform: rotate(90deg);
  border-radius: 50%;
  border: 1px solid #545454;
  text-align: center;
  padding: 3px 0px 15px;
}
.slider-theme .slick-prev {
  display: block;
  display: block;
  width: 36px;
  height: 36px;
  transform: rotate(-90deg);
  border-radius: 50%;
  border: 1px solid #545454;
  text-align: center;
  padding: 3px 0px 15px;
}
.slick-prev:before,
.slick-next:before {
  content: "\2191" !important;
}
@media (max-width: 991.98px) {
  .min-height-100per.Team {
    padding-top: 0;
  }
  .Team h1 {
    font-size: 36px;
    line-height: 36px;
    text-align: left;
    margin-bottom: 2rem;
  }
}
@media (min-width: 500px) and (max-width: 767.98px) {
  .itemslide {
    z-index: 2;
    background: unset;
    background-repeat: no-repeat;
    background-size: 100% 99%;
    display: block;
    padding: 25px;
    border: 1px solid #e340382b;
    border-radius: 15px;
  }
  .min-height-100per.Team {
    margin-top: -3rem;
  }
}
