.card.card-custom > .card-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  min-height: 70px;
  background-color: transparent;
  border-bottom: 1px solid #eee !important;
}
.card-header:first-child {
  border-radius: calc(0.42rem - 1px) calc(0.42rem - 1px) 0 0;
}
.card.card-custom > .card-header .card-title,
.card.card-custom > .card-header .card-title .card-label {
  font-weight: 400;
  font-size: 1.125rem;
  color: #181c32;
}
.card-header {
  padding: 1rem 1.25rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #ebedf3;
}
.card.card-custom > .card-header .card-title .card-label {
  margin: 0 0.75rem 0 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-weight: 500;
}
.font-size-sm {
  font-size: 12px;
}
.text-muted {
  color: #b5b5c3 !important;
}
.card.card-custom > .card-header .card-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0.5rem;
  margin-left: 0;
}
.table > :not(caption) > * > * {
  vertical-align: middle;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  text-align: left;
}

.admin .input-icon span {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: calc(1.5em + 1.3rem + 2px);
  color: #969696;
}
.input-icon {
  position: relative;
}
.input-icon .form-control {
  padding-left: calc(1.5em + 1.3rem + 2px);
}
.admin .btn {
  font-size: 13px;
  font-weight: 400;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
}
.btn.btn-light-primary,
.offcanvas .btn.btn-light-primary {
  background-color: #f1f3ff;
  border-color: transparent;
  color: #212529;
}
.admin .input-icon select.form-select {
  color: #969696;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  padding: 0.65rem 1rem;
  font-size: 14px;
  padding-left: calc(1.5em + 1.3rem + 2px);
  font-weight: 400;
  line-height: 1.5;
}
.admin .input-icon select.form-select option {
  padding: 5px;
}
.admin .card-toolbar a {
  margin: 0rem;
  margin-right: 0;
}
.table-admins th {
  font-weight: 600;
}
.admin td .btn {
  padding: 8px !important;
  width: 38px;
  height: 38px;
  text-align: center;
  color: #585858;
}
.admin .h3,
.admin h3 {
  font-size: 16px;
}
.admin .modal-footer {
  border-top: 0px;
}
.select-choose-box {
  background: #eeeeee00;
  border: 1px solid #eee;
  padding: 15px 30px;
  border-radius: 4px;
}
.select-choose-box .choose-box label {
  margin-bottom: 7px;
  margin-left: 10px;
  cursor: pointer;
}
.select-choose-box .choose-box:last-child label {
  margin-bottom: 0;
}
.select-choose-box .choose-box.perParent {
  margin-top: -35px;
  background: #ffffff;
  display: block;
  width: fit-content;
  margin-left: -16px;
  padding-left: 5px;
  padding-right: 15px;
  margin-bottom: 5px;
}
.select-choose-box label span:first-child {
  padding: 5px 7px 5px 5px;
}
.permission {
  border-bottom: 1px solid #eee;
  padding-bottom: 0.7rem;
}
.border-style {
  border-top: 1px solid #eee;
  padding-top: 30px;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}
.basic-multi-select .select__control {
  min-height: calc(1.5em + 1.3rem + 2px) !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.375rem !important;
}
.admin-settings .input-icon .form-control {
  padding-left: 1rem;
}
.select-choose-box .choose-box:first-child label {
  font-size: 16px;
  font-weight: 600;
}
div#multiple-select-Roles {
  padding-left: 6px;
}
.perParent span {
  font-weight: 600;
}
.choose-box input {
}
.activeButton a.page-link {
  background-color: #0d6efd;
  color: #fff;
}
.perChild {
  padding-left: 20px;
}
.float-right {
  float: right;
}
.MuiButtonBase-root.MuiChip-root.MuiChip-outlined.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-clickable.MuiChip-clickableColorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-outlinedDefault {
  background-color: hsl(0, 0%, 90%);
  border-radius: 2px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;
  border: 0;
  height: 24px;
}
span.MuiChip-label {
  border-radius: 2px;
  color: hsl(0, 0%, 20%);
  font-size: 12px;
  overflow: hidden;
  padding: 3px;
  padding-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.basic-multi-select:before {
  border: 0px solid #ced4da !important;
  border-radius: 0.375rem !important;
  height: -webkit-fill-available;
}
label.labelinput {
  position: relative;
  left: 8px;
  border-right: 1px solid #ccc;
  width: -moz-fit-content;
  width: fit-content;
  padding-right: 15px;
  padding-left: 8px;
  line-height: 2.5;
  top: 6px;
  color: #a4a4a4;
  margin-bottom: 0;
}
label.labelinput {
  position: relative;
  left: 8px;
  border-right: 1px solid #ccc;
  width: -moz-fit-content;
  width: fit-content;
  padding-right: 15px;
  padding-left: 8px;
  line-height: 2.9;
  top: 1px;
  color: #a4a4a4;
  transform: unset !important;
  transition: unset !important;
  font-size: 14px;
}
/* label#Roles-multiple-name-label.labelinput {
  color: rgb(0 0 0 / 52%);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 3;
  top: 7px;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 101%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: unset;
  -moz-transform: translate(0, -7px) scale(1);
  -ms-transform: translate(0, -7px) scale(1);
  transform: translate(0, -7px) scale(1);
} */
label.labelinput.Mui-focused {
  color: #b3b3b3;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.basic-multi-select:after {
  border-bottom: 0px solid #1976d2 !important;
}
li.MuiButtonBase-root {
  font-size: 12px;
  color: #6a6a6a;
}
div#multiple-select-Roles:focus {
  background: #fff0 !important;
}
label
  ~ .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.basic-multi-select {
  margin-top: 0px;
  width: 100%;
  margin-left: -58px;
  padding-left: 67px;
  display: block;
}
button.IconButton-custom {
  font-size: 14px;
  opacity: 0.6;
  margin-right: 20px;
  top: 2px;
}
i.fas.fa-times.MuiChip-deleteIcon.MuiChip-deleteIconMedium.MuiChip-deleteIconColorDefault.MuiChip-deleteIconOutlinedColorDefault {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;
  font-size: 12px;
  margin-left: 4px;
}
.page-link {
  border-radius: 4px;
  margin: 0 5px;
  min-width: 30px;
  text-align: center;
}
.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.spinner-content {
  width: 94.7%;
  margin: 0 auto;
  text-align: center;
  position: absolute;
}
.admin .input-icon span.reset-select {
  position: absolute;
  z-index: 9;
  right: 25px;
  left: auto;
  font-size: 18px;
  font-weight: 600;
  color: #4067ac;
  top: -5px;
  cursor: pointer;
}
.reset-select {
  opacity: 0;
}
.input-icon:hover .reset-select {
  opacity: 1;
}
.select__placeholder:before {
  content: "\f002";
  padding: 6px 16px 6px 5px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #969696;
}
.filter-title {
  padding: 0.75rem 1.25rem;
  background-color: #f1f3ff;
  border-bottom: 0px solid #e3e6f0;
  margin-bottom: 10px;
  border-radius: 0.375rem;
  cursor: pointer;
}
.filter-title .card-label i.list {
  float: right;
  margin-top: 5px;
}
.formControlSelect,
.MuiFormControl-root.css-tyvnos-MuiFormControl-root {
  border: 1px solid #ced4da !important;
  border-radius: 0.375rem !important;
  height: fit-content;
  display: -webkit-box !important;
}
.border-bottom-span {
  border-bottom: 1px solid;
}
.Switch-div {
  zoom: 0.85;
}
.TablePagination-style p {
  margin-bottom: 0;
  background: #eeeeee5c;
  padding: 5px 10px;
  border-radius: 4px;
}
.TablePagination-style button {
  border: 1px solid #eee;
  border-radius: 4px;
  margin: 2px;
  padding: 4px;
  color: #0d6efd;
}
.TablePagination-style .MuiInputBase-root.MuiInputBase-colorPrimary {
  margin: 0 15px 0 0;
  border-radius: 4px;
  padding: 0px;
}
