.heroSection {
  background: url(../../assets/home/landing-hero-Image.png);
  background-size: cover;
  background-color: #2b2b2b;
}

.homeFlex {
  background-color: #2b2b2b;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .heroSection {
    background: unset;
  }
}
