.wallet-connect-text h1 {
  font-family: "Sora";
  font-weight: 700;
  font-size: 45px;
  line-height: 45px;
  color: #ffffff;
  text-align: left;
}

.wallet-connect-text span {
  padding: 16px 24px;
  background: linear-gradient(
    96.27deg,
    #f85e5b 12.79%,
    #886e6b 50.13%,
    #ff786a 107.19%
  );
  border-radius: 57px;
  font-family: "Sora";
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #fcfcfd;
  width: 303px;
  cursor: pointer;
}

.disc-wallet-row {
  margin: 5vh auto 10vh;
}

.wallet-connect-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 29px;
}

@media (min-width: 768px) {
  .disc-wallet-row {
    height: calc(100vh - 100px - 190px);
  }
}

@media (max-width: 1200px) {
  .wallet-connect-text h1 {
    font-size: 48px;
    line-height: 60px;
  }
  .wallet-connect-text span {
    font-size: 16px;
    line-height: 16px;
  }
}

@media (max-width: 992px) {
  .disc-wallet-row {
    justify-content: space-between;
  }
  .wallet-connect-text h1 {
    font-size: 32px;
    line-height: 40px;
    width: 100%;
    text-align: center;
  }
  .wallet-connect-text span {
    font-size: 14px;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .wallet-connect-text h1 {
    font-size: 32px;
    line-height: 40px;
  }
  .wallet-connect-text span {
    width: 100%;
  }
}
