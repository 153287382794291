.min-height-100 {
  min-height: calc(100vh - 100px);
}
.hero-content h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 72px;
  margin-bottom: 2rem;
  margin-top: 6rem;
}
.hero-content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #a3a3a3;
  margin-bottom: 1.5rem;
  letter-spacing: 0.8px;
}
img.telvimg {
  width: 100%;
  height: auto;
  margin-top: -8%;
}
.bg-hero {
  background: linear-gradient(
    180deg,
    rgba(36, 35, 44, 0) 0%,
    rgba(36, 35, 44, 0.62) 124.91%
  );
}
@media (max-width: 768px) {
  .bg-hero {
    background: unset;
  }
  img.telvimg {
    margin-top: 10%;
  }
  .hero-content h1 {
    font-size: 45px;
    line-height: 55px;
    margin-top: 2rem;
  }
  .hero-content p {
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: #a3a3a3;
    margin-bottom: 1.5rem;
    letter-spacing: 0.5px;
    text-align: justify;
  }
}
@media (max-width: 991px) {
}
