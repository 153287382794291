.ConnectWalletModal {
  box-shadow: rgb(227 64 56 / 32%) 20.6564px 10.3282px 33.7026px;
  background: linear-gradient(
    102.19deg,
    rgb(245 104 97) 1.65%,
    rgb(245 104 97) 69.89%
  ) !important;
}
.ConnectWalletModal .modal-header {
  padding: 13px;
}
.ConnectWalletModal .modal-header {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  border-bottom: 1px solid rgb(255 255 255 / 0%);
  color: rgb(255, 255, 255);
  padding-bottom: 10px !important;
  padding-top: 0;
}
.ConnectWalletModal .modal-header button {
  cursor: pointer;
  color: rgb(255, 255, 255);
}
.ConnectWalletModal .connect-wallet-modal-body {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.ConnectWalletModal .connect-wallet-modal-body button {
  cursor: pointer;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  gap: 5px;
  background: transparent;
  border-radius: 7px;
  color: rgb(255, 255, 255);
  padding: 10px 0px;
  border: 1px solid rgb(255, 255, 255);
}
button.wallet-connect img,
button.metamask img {
  width: 20px;
  height: 20px;
}
.ConnectWalletModal .modal-title {
  font-size: 16px;
}
