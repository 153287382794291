.w-250px {
  width: 250px !important;
}
.pb-9,
.py-9 {
  padding-bottom: 2.25rem !important;
}
.pt-9,
.py-9 {
  padding-top: 2.25rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.7rem !important;
}
.font-size-h5 {
  font-size: 1.25rem !important;
}
.mb-6,
.my-6 {
  margin-bottom: 1.5rem !important;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
span.form-text.text-muted {
  font-size: 0.9rem;
}
.image-input {
  position: relative;
  display: inline-block;
  border-radius: 0.42rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.image-input.image-input-outline .image-input-wrapper {
  border: 3px solid #fff;
  -webkit-box-shadow: 0 0.5rem 1.5rem 0.5rem rgb(0 0 0 / 8%);
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgb(0 0 0 / 8%);
}
.image-input .image-input-wrapper {
  width: 120px;
  height: 120px;
  border-radius: 0.42rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.btn.btn-icon.btn-xs {
  height: 24px;
  width: 24px;
  padding: 0;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.btn.btn-icon.btn-circle {
  border-radius: 50%;
  z-index: 9;
}
.btn.btn-white.btn-shadow {
  -webkit-box-shadow: 0 9px 16px 0 rgb(24 28 50 / 25%) !important;
  box-shadow: 0 9px 16px 0 rgb(24 28 50 / 25%) !important;
}
.image-input [data-action="change"] {
  cursor: pointer;
  position: absolute;
  right: -10px;
  top: -10px;
}
.btn.btn-white {
  color: #3f4254;
  background-color: #fff;
  border-color: #fff;
}
.btn.btn-white:hover {
  color: #3f4254;
  background-color: #efefef;
  border-color: #efefef;
}
.btn.btn-icon i {
  padding: 0;
  margin: 0;
}
.text-right {
  text-align: right !important;
}
.separator.separator-dashed {
  border-bottom: 1px dashed #ebedf3;
}
.image-input [data-action="change"] input {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden;
  opacity: 0;
}
.form-check-input {
  padding: 10px;
}
.form-switch .form-check-input {
  width: 3em;
}
.icon-sm {
  font-size: 0.75rem !important;
}
.image-input [data-action="cancel"],
.image-input [data-action="remove"] {
  position: absolute;
  right: -10px;
  bottom: -5px;
}
.image-input [data-action="cancel"] {
  display: none;
}
.card.card-custom > .card-header .card-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0.5rem 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.card-custom .col-form-label {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.1rem + 1px);
}
.form-control-lg {
  height: calc(1.5em + 1.65rem + 2px);
  padding: 0.825rem 1.42rem;
  font-size: 1.08rem;
  line-height: 1.5;
  border-radius: 0.42rem;
}

.navi.navi-active .navi-item .navi-link.active {
  background-color: #f3f6f9;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  color: #2832a1;
}
.navi .navi-item .navi-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.75rem 1.5rem;
}
.navi.navi-active .navi-item .navi-link.active i {
  color: #2832a1;
}
.navi .navi-item .navi-link .navi-text {
  color: #3f4254;
}
.navi.navi-link-rounded .navi-item .navi-link {
  border-radius: 0.42rem;
}
.danger-star {
  color: red;
  font-size: 16px;
}
.loading-section {
  display: block;
  position: relative;
}
.input-group.input-group-solid {
  align-items: center;
  background-color: #f3f6f9;
  border-radius: 0.42rem;
}
.my-10 {
  margin-bottom: 1.5rem !important;
  margin-top: 1.5rem !important;
}
span.input-group-text {
  height: 100%;
  border: 0;
  background: 0;
  color: #d3d3d3;
}
.input-group-lg > .form-control {
  padding: unset;
  font-size: 14px;
  border-radius: 0.5rem;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
}
.form-control:disabled {
  background-color: #f3f6f9;
  opacity: 1;
  border: 0;
}
.loading-section .spinner-border {
  position: absolute;
  right: 20%;
  z-index: 1;
  top: 25px;
}
.card-toolbar .spinner-border {
  margin-right: 10px;
}
span.burger-menu-icon {
  cursor: pointer;
}
.btn {
  padding: 0.65rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.42rem;
}

/* modal style */
.wizard > .steps > ul {
  padding: 0;
  margin-bottom: 0;
  display: flex;
}
.wizard > .steps > ul li {
  float: none;
  display: block;
  width: auto;
}
.disabled {
  pointer-events: none;
  opacity: 0.4;
}
.wizard > .steps > ul li + li {
  position: relative;
}
.wizard > .steps > ul li + li::before {
  content: "\f061" !important;
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
  font-weight: 600;
  color: #b4bdce;
  position: absolute;
  top: 50%;
  left: -26px;
  line-height: 0;
  display: none;
}
.wizard > .steps .disabled a,
.wizard > .steps .disabled a:active,
.wizard > .steps .disabled a:hover {
  color: #97a3b9;
}
.wizard > .steps a,
.wizard > .steps a:active,
.wizard > .steps a:hover {
  color: #1c273c;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
}
.wizard > .steps .disabled a .number,
.wizard > .steps .disabled a:active .number,
.wizard > .steps .disabled a:hover .number {
  color: #97a3b9;
  border-color: #b4bdce;
}
.wizard > .steps a .number,
.wizard > .steps a:active .number,
.wizard > .steps a:hover .number {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
  border: 2px solid #7987a1;
  border-radius: 100%;
}
.wizard > .content {
  padding: 40px;
  min-height: 286px;
  border-top: 1px solid rgba(72, 94, 144, 0.16);
  border-bottom: 1px solid rgba(72, 94, 144, 0.16);
  font-size: inherit;
  text-align: center;
}
.wizard > .steps .current a .number,
.wizard > .steps .current a:active .number,
.wizard > .steps .current a:hover .number {
  border-color: #3a3a3a;
  background-color: #3a3a3a;
  color: #fff;
}
.modal .modal-header .modal-title {
  font-weight: 500;
  font-size: 1.125rem;
  color: #000;
}
.css-ovthrp {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #eceaef;
  border-radius: 4px;
  margin-bottom: 16px;
  width: 192px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 64px;
}
.css-1i0rbqg {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #eceaef;
  border-radius: 4px;
  width: 192px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 64px;
}
.css-dx6lmr {
  box-sizing: border-box;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 20px auto 0;
}
.css-104e7hr {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  width: 16px;
}
.modal .modal-content .modal-body img.css-1sysnl8 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  width: 24px;
}
.css-1sgz1lk {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  margin-left: 16px;
}
.css-vurnku {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  text-align: left;
}
.css-1gubtqr {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  white-space: nowrap;
  font-weight: 500;
  font-size: 17px;
  text-align: left;
  text-decoration: unset;
}
.wizard a {
  color: #2832a1;
}
.wizard > .actions a,
.wizard > .actions a:active,
.wizard > .actions a:hover {
  display: block;
  background-color: #3a3a3a;
  padding: 9px 20px;
  line-height: 1.539;
  color: #fff;
  border-radius: 0.25rem;
}
.wizard > .actions .disabled a,
.wizard > .actions .disabled a:active,
.wizard > .actions .disabled a:hover {
  background-color: #97a3b9;
}
.css-weqpav {
  box-sizing: border-box;
  min-width: 0;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto 16px;
}

.css-1xfmg0p {
  box-sizing: border-box;
  min-width: 0;
  width: 96px;
  margin: 0 0 16px;
}
.img-code {
  height: 96px;
  width: 96px;
}
.css-wiulhc {
  width: 24px;
}
.css-1t5xnih {
  box-sizing: border-box;
  min-width: 0;
  width: 300px;
  margin: 0;
}
.css-vurnku {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
}
.css-1gv6rnn {
  -webkit-box-pack: start;
  justify-content: flex-start;
  box-sizing: border-box;
  min-width: 0;
  display: flex;
  margin: 0;
}
.css-ryy4eq {
  box-sizing: border-box;
  min-width: 0;
  display: inline-block;
  font-size: 16px;
  height: 40px;
  background-color: #fafafa;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 16px 0 0;
}
.form-control-lg.Authenticator-input {
  font-size: 25px;
  padding: 10px;
  height: 40px;
  letter-spacing: 18px;
}
.wizard > .steps a.done .number {
  /* border-color: #3a3a3a;
    background-color: #3a3a3a;
    color: #fff; */
}
.wizard > .steps a.disabled.done {
  pointer-events: none;
  opacity: 1;
  color: #1c273c;
}
.VerificationInput .vi__character {
  background: #fff0;
}
.VerificationInput .vi__container {
  gap: 17px;
}
.text-success {
  color: #1bc5bd !important;
}
.VerificationInput input:-webkit-autofill,
.VerificationInput input:-webkit-autofill:hover,
.VerificationInput input:-webkit-autofill:focus,
.VerificationInput input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #001770 inset !important;
  caret-color: #fff;
}
/* modal style */
.wizard > .steps > ul li + li {
  margin-left: 40px;
}
.wizard > .steps {
  padding: 15px 0px;
}
.wizard > .steps > ul li + li::before {
  display: block;
}
.wizard > .steps .disabled .title {
  display: inline-block;
}
.wizard > .steps > ul li .title {
  margin-left: 10px;
}
.wizard > .actions {
  padding: 15px 20px;
}
.wizard > .actions > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
}
.wizard > .steps > ul li a {
  cursor: context-menu;
}
.VerificationInput .vi__container {
  height: 45px;
}
.VerificationInput .vi__character {
  height: 45px;
  border-image: initial;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid rgba(20, 20, 20, 0.1) !important;
}
/* modal style */
.ml-lg-8,
.mx-lg-8 {
  margin-left: 2rem !important;
}
/* modal style */
@media (max-width: 767.98px) {
  .wizard > .steps > ul li + li::before {
    display: none;
  }
  .wizard > .steps > ul li + li {
    margin-left: 10px;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 0rem !important;
  }
  .wizard > .steps {
    padding: 15px 5px;
  }
  .css-104e7hr {
    display: none;
  }
  .w-90-mobile.offcanvas.offcanvas-start {
    padding: 2rem 2.25rem;
    width: 90% !important;
  }
  .wizard > .steps > ul li .title {
    display: none !important;
  }
  .wizard > .steps > ul li.current span.title {
    display: inline-block !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 0rem !important;
  }
  .wizard > .steps > ul li .title {
    display: none !important;
  }
  .wizard > .steps > ul li.current span.title {
    display: inline-block !important;
  }
  .w-90-mobile.offcanvas.offcanvas-start {
    padding: 2rem 2.25rem;
    width: 320px !important;
  }
}
@media (max-width: 991.98px) {
  .symbol.symbol-100 .symbol-label {
    width: 60px;
    height: 60px;
  }
}
@media (min-width: 992px) {
  /* modal style */
  .wizard > .steps > ul li + li {
    margin-left: 40px;
  }
  .wizard > .steps {
    padding: 15px 0px;
  }
  .wizard > .steps > ul li + li::before {
    display: block;
  }
  .wizard > .steps .disabled .title {
    display: inline-block;
  }
  .wizard > .steps > ul li .title {
    margin-left: 10px;
  }
  .wizard > .actions {
    padding: 15px 20px;
  }
  .wizard > .actions > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
  }
  /* modal style */
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 2rem !important;
  }
}
/* modal style */
@media (min-width: 1200px) {
  .wizard > .steps a,
  .wizard > .steps a:active,
  .wizard > .steps a:hover {
    justify-content: flex-start;
  }
}
/* modal style */
@media (min-width: 1400px) {
  .w-xxl-350px {
    width: 350px !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 1.25rem !important;
  }
}
