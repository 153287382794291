.token-intro {
height: 100vh;
}

.token-description {
  font-family: 'Sora';
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #a3a3a3;
  margin-top: 1.5rem;
}

@media (max-width: 768px) {
  .mt-10p {
    margin-top: 10% !important;
    max-height: 60vh;
  }

  .televerse-world-head {
    font-weight: 700;
    font-size: 45px;
    line-height: 82px;
    margin-top: 2rem;
  }
.token-description {
    font-size: 17px;
    line-height: 144%;    
    letter-spacing: -0.025em;
    margin-bottom: 1.5rem;
}
.token-intro {
  height: 100%;
  }
}