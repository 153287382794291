.bg-balance-details {
  background: #3b3b3b;
  border-radius: 20px;
}

.text-right {
  text-align: right;
}

.progress {
  background: #ffffff05;
  height: 8px;
}

.progress-bar {
  background: #9f44f9;
}

.pb-8 {
  padding-bottom: 8px;
}

/* notes page */
.notes-page {
  font-family: "Sora";
  font-size: 14px;
  font-feature-settings: "pnum" on, "lnum" on;
  margin-top: 16px;
}

.notes-page p {
  margin: 0;
  margin-bottom: 8px;
}
.notes-page ul {
  padding-left: 1rem;
}
.notes-page ul li {
  list-style: square;
  margin-bottom: 8px;
  color: #ced3df;
}
