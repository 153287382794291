.marketplace-container {
  height: 100vh;
}

.marketplace-title {
  font-family: "Sora";
  font-weight: 700;
  font-size: 64px;
  line-height: 72px;
  text-align: center;
  color: #ffffff;
}

.marketplace-title-row {
  margin-bottom: 29px;
}

.marketplace-description {
  font-family: "Sora";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #a3a3a3;
}

.marketplace-description-row {
  margin-bottom: 84px;
}

.trending-collection {
  font-family: "Sora";
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
  width: auto;
}

.marketplace-btns {
  margin-bottom: 24px;
}

.see-all {
  font-family: "Sora";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  width: auto;
}
/* Card Style */

.whole-card {
  background: #3b3b3b;
  backdrop-filter: blur(30.0041px);
  border-radius: 16px;
  width: 251px;
  height: 440px;
}

.card-title {
  font-family: "Sora";
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
}

.mb-18 {
  margin-bottom: 18px;
}
.user-image {
  position: relative;
  margin-right: 13px;
}
.verification-badge {
  position: absolute;
  top: 20px;
  left: 35px;
}
.card-owner {
  font-family: "Sora";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  color: #a3a3a3;
}
.card-owner-name {
  font-family: "Sora";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: #ff4a41;
}

.card-price {
  font-family: "Sora";
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.telv-symbol {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.place-bid-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 12px;
  background: linear-gradient(
    96.27deg,
    #115743 12.79%,
    #4eb093 50.13%,
    #115743 107.19%
  );
  border-radius: 77px;
  width: 92px;
  height: 32px;

  font-family: "Sora";
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #fcfcfd;

  cursor: pointer;
}
button.slick-arrow.slick-prev {
  width: 36px;
  height: 36px;
  transform: rotate(270deg);
  border-radius: 50%;
  border: 1px solid #545454;
  text-align: center;
  padding: 3px 0px 15px;
  position: absolute;
  top: -72px;
  right: 55px;
  left: auto;
  z-index: 1;
}
button.slick-arrow.slick-next {
  position: absolute;
  top: -72px;
  right: 15px;
  left: auto;
  z-index: 1;
}
@media (max-width: 992px) {
  .trending-collection {
    font-family: "Sora";
    font-weight: 400;
    font-size: 28px;
    line-height: 30px;
    color: #ffffff;
    width: 125px;
  }
  .see-all {
    display: none;
  }
  .whole-card {
    width: auto;
    height: auto;
  }
  .marketplace-title {
    font-size: 50px;
    line-height: 60px;
  }
  .marketplace-container {
    height: auto;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .marketplace-container {
    height: auto;
  }
  .whole-card {
    margin: 3px;
    width: auto;
    height: auto;
    zoom: 0.83;
  }
  .card-price {
    font-size: 14px;
  }
  .telv-symbol {
    margin-right: 5px;
  }
}
