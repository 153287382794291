.DistributionVesting h2 {
  font-weight: 700;
  font-size: 45px;
  line-height: 72px;
  margin-bottom: 3rem;
}
.DistributionVesting .table > :not(caption) > * > * {
  text-align: center;
  padding: 0.5rem 0.5rem;
  background-color: #202020;
  border-bottom-width: 0px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  font-weight: 400;
  font-size: 16px;
  line-height: 35px;
  letter-spacing: 0.4px;
  color: #a3a3a3 !important;
}
.DistributionVesting .table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: #3b3b3b;
  color: var(--bs-table-striped-color);
}
.DistributionVesting .table-responsive {
  border-radius: 14px;
  padding-top: 27px;
}
.titletable {
  background: linear-gradient(
    96.27deg,
    #115743 12.79%,
    #4eb093 50.13%,
    #115743 107.19%
  );
  border: 1px solid #e3403859;
  border-radius: 10px;
  color: #fff !important;
  padding: 15px 38px;
  position: relative;
  z-index: 1;
  top: -25px;
}
.DistributionVesting table.table.table-dark.table-striped.table-hover {
  margin-bottom: 0;
}
.DistributionVesting .table-striped > tbody > tr:last-child td {
  padding-bottom: 30px;
}
.DistributionVesting .table-striped > tbody > tr td:first-child {
  text-align: left;
  padding-left: 45px;
}
.DistributionVesting table.table thead tr th:first-child {
  border-top-left-radius: 14px;
}
.DistributionVesting table.table thead tr th:last-child {
  border-top-right-radius: 14px;
}

@media (max-width: 991.98px) {
  .titletable {
    background: linear-gradient(
      96.27deg,
      #115743 12.79%,
      #4eb093 50.13%,
      #115743 107.19%
    );
    border: unset;
    border-radius: unset;
    color: #fff !important;
    padding: 10px 15px;
    /* z-index: 1; */
    top: unset;
    display: flex;
    position: relative;
    width: max-content;
  }
  .DistributionVesting h2 {
    font-size: 38px;
    line-height: 45px;
    text-transform: capitalize;
  }
  .DistributionVesting table.table thead tr th {
    background: linear-gradient(
      96.27deg,
      #115743 12.79%,
      #4eb093 50.13%,
      #115743 107.19%
    );
  }
  .DistributionVesting .table-striped > tbody > tr td:first-child {
    padding-left: 23px;
  }
  .DistributionVesting .table-responsive {
    padding-top: 0;
  }
  img.chart-center {
    display: none;
  }
}
