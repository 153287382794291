.newsTicker {
  overflow: hidden;
  position: relative;
  height: 30px;
  background-color: #3b3b3b;
  color: white;
}

.newsTickerItems {
  display: flex;
  position: absolute;
  animation: ticker 3500s linear infinite;
}

.newsItem {
  white-space: nowrap;
  padding: 0 50px;
  font-size: 16px;
  line-height: 30px;
}

@keyframes ticker {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes ticker {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.newsTicker a {
  color: white;
}
