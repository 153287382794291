.coming-soon-section {
  background: #3e405b;
  border-radius: 20px;
}
.min-hight-80 {
  min-height: 55vh;
  padding: 2rem 0;
  overflow: hidden;
}

.bg-token-coming-soon {
  background: url(../../../../assets/token-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 10%;
  border-radius: 20px;
  margin: 4rem 0;
}
.private-token h1 {
  font-family: "Sora";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  color: #fcfcfd;
  margin-bottom: 1rem;
}
.private-token h3 {
  font-family: "Sora";
  font-weight: 700;
  font-size: 45px;
  letter-spacing: 0.195em;
  text-transform: uppercase;
  background: linear-gradient(
    271.22deg,
    #ffffff 0.85%,
    #ffffff 63.84%,
    #ffffff 101.65%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.start-soon-text {
  margin-bottom: 30px;
}

.private-token img {
  margin-top: 3rem;
}
.presale-div-right {
  position: relative;
  height: 55vh;
}

.live-timers .count {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: rgba(78, 78, 97, 0.05);
  backdrop-filter: blur(4.5px);
  border-radius: 6px;
}

.live-timers .count h1 {
  margin-top: 2rem;
  font-family: "Sora";
  font-size: 32px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.143359px;
  color: #ffffff;
}

.live-timers .count p {
  font-family: "Sora";
  font-weight: 300;
  font-size: 8.98981px;
  line-height: 36px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.184593px;
  text-transform: uppercase;
  color: #fff;
}

@media (max-width: 1199px) {
  .private-token h1 {
    font-size: 28px;
  }
  .private-token h3 {
    font-size: 40px;
  }
}

@media (max-width: 991.98px) {
  .bg-private-token {
    background: #3b3b3b;
    border-radius: 20px;
    margin: 4rem 0;
    padding: 2rem 2rem;
  }
  .min-hight-80 {
    min-height: auto;
    height: 90vh;
    padding: 4rem 0 2rem;
  }
  .private-token {
    text-align: center;
  }
  .bg-token-coming-soon {
    background: url(../../../../assets/mob-token-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

@media (max-width: 768px) {
  .private-token h1 {
    font-weight: 700;
    font-size: 28px;
    letter-spacing: 0.015em;
    margin-bottom: 20px;
  }
  .private-token h3 {
    font-weight: 600;
    font-size: 40px;
    letter-spacing: 5px;
    text-transform: uppercase;
  }
  .private-token img {
    margin-top: 2rem;
  }
}

@media (max-width: 575px) {
  .private-token h1 {
    font-size: 24px;
  }
  .private-token h3 {
    font-size: 32px;
  }
}
