.wallet-connect-text h1 {
  font-family: "Sora";
  font-weight: 700;
  font-size: 65px;
  line-height: 82px;
  color: #ffffff;
  text-align: left;
}
.mil-overlay {
  background-color: #000000b5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  backdrop-filter: blur(24px);
}
.disc-wallet-row {
  margin: 6vh auto 6vh;
}
.conectTextLine {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    96.27deg,
    #eec014 11.79%,
    #6be619 47.13%,
    #ffffff 46.19%
  );
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: bold;
  cursor: pointer;
}

.disconnectImg {
  margin-top: -4px;
  margin-right: 5px;
  width: 24px;
  height: 24px;
}
.custom-secondary .disconnectImg {
  margin-top: 6px;
}
.wallet-connect-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 29px;
  text-align: left;
}
h4.titleConnection {
  font-size: 38px;
  font-weight: 600;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(96.27deg, #fff 11.79%, #fff 34.13%, #fff 46.19%);
  -webkit-background-clip: text;
  background-clip: text;
}
@media (min-width: 768px) {
  .disc-wallet-row {
    height: calc(100vh - 100px - 190px);
  }
}

@media (max-width: 1200px) {
  .wallet-connect-text h1 {
    font-size: 48px;
    line-height: 60px;
  }
  .wallet-connect-text span {
    font-size: 16px;
    line-height: 16px;
  }
}

@media (max-width: 992px) {
  .disc-wallet-row {
    justify-content: space-between;
    min-height: 60vh;
  }
  .wallet-connect-text h1 {
    font-size: 32px;
    line-height: 40px;
    width: 100%;
    text-align: center;
  }
  .wallet-connect-text span {
    font-size: 14px;
    width: 100% !important;
  }
}

@media (max-width: 576px) {
  .wallet-connect-text h1 {
    font-size: 32px;
    line-height: 40px;
  }
  .wallet-connect-text span {
    width: 100% !important;
  }
}
