.Advisors .title-div h2 {
  background: linear-gradient(
    92.1deg,
    #e34038 -12.35%,
    #e34038 30.1%,
    #e34038 104.64%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 600;
  font-size: 45px;
  line-height: 57px;
  width: fit-content;
}
.card-advisor h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 37px;
  text-transform: capitalize;
  color: #ffffff;
}
.card-advisor p {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #ff4a41;
  margin-bottom: 10px;
}
.card-advisor span {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #969696;
}
.content-advisory {
  background: #2b2b2b;
  padding: 50px 0 100px;
  box-shadow: -53px 0px 77px -46px rgb(38 59 97 / 8%);
}
.card-advisor {
  margin: 0 auto;
  padding: 0 38px;
}
.card-advisor img {
  margin-bottom: 1rem;
  width: 100%;
}
.advisory-bg {
  background-image: url(../../../assets/home&token/Advisors.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  background-color: #2b2b2b;
  padding-top: 4rem !important;
  margin-top: 0rem;
}
@media (max-width: 991.98px) {
  .min-height-100per.Advisors {
    margin-top: -7rem;
  }
  .advisory-bg {
    background-repeat: no-repeat;
    background-position: 50% 2%;
    background-size: 146%;
    background-color: #2b2b2b;
    padding-top: 0rem !important;
  }
  .content-advisory {
    padding: 30px 0 120px;
  }
  .content-advisory {
    background: unset;
  }
  .Advisors .title-div h2 {
    margin: 0 auto;
    font-size: 38px;
    line-height: 38px;
  }
  .card-advisor h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 25px;
    text-transform: capitalize;
    color: #ffffff;
    margin-top: 1rem;
  }
  .img-info {
    display: flex;
  }
  .card-advisor img {
    margin-bottom: 1rem;
    width: 80px;
    margin-right: 15px;
  }
  .card-advisor {
    margin: 0 auto;
    padding: 23px 23px;
    background: #0e0c16;
    border-radius: 23px;
    margin-bottom: 2rem;
    border: 1px solid #e3403833;
    box-shadow: #2014741c 0px 2px 8px 0px;
  }
}
