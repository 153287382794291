@import url("https://fonts.googleapis.com/css2?family=Sora:wght@200;300;400;500;600;700;800&display=swap");
body {
  font-family: "Sora", sans-serif;
}
.nav-link:focus,
.nav-link:hover {
  color: rgb(255 255 255 / 70%);
}
body {
  background: #2b2b2b;
  color: #fff;
  position: relative;
  overflow-x: hidden;
  /* background-image: url(../../../assets/bg3.png); */
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  transition: background-image 0.9s ease;
  height: 100%;
  /* backdrop-filter: blur(2px); */
}
.navbar-nav a.homeIconPage.homeIconPage {
  background: #3e987e6e;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  padding: 0 !important;
  text-align: center;
  display: flex;
  align-items: center;
  margin: 0 auto;
}
a.nav-link.homeIconPage svg {
  width: 25px;
  height: auto;
  margin: 0 auto;
}
body.ConnectToYourWallet {
  background-image: url(../../../assets/bg6.png);
}
body.WaitingForSignature {
  background-image: url(../../../assets/bg2.png);
}
body.ISOVER {
  /* background-image: url(../../../assets/bg4.png); */
}
body.UpdateNetWorkPage {
  background-image: url(../../../assets/bg6.png);
}
.footer {
  display: none !important;
}
body.WaitingForSignature .footer,
body.ConnectToYourWallet .footer {
  display: flex !important;
}
.nav-link {
  color: #fff;
  font-size: 1rem;
}
.navbar-nav a {
  padding: 0.85rem 1rem !important;
}
nav.navbar {
  height: 100px;
  width: 100%;
}

@media (max-width: 991.98px) {
  nav.navbar {
    width: auto;
  }
}

.Visting .footer {
  display: none;
}
button.custom-primary.btn.btn-primary {
  background: linear-gradient(96.27deg, #115743 12.79%, #4eb093 50.13%, #115743 107.19%);
  border-radius: 42px;
  border-color: #ff4a41;
}
.custom-primary.btn.btn-primary {
  background: linear-gradient(96.27deg, #115743 12.79%, #4eb093 50.13%, #115743 107.19%);
  border-radius: 42px;
  border-color: #ff4a41;
}
.navbar-nav button.custom-primary.btn.btn-primary {
  max-height: 48px;
  padding: 0.4rem 2rem 0.55rem !important;
  margin-left: 1.7rem;
  height: 48px;
}
button.custom-primary.btn.btn-primary:hover {
  background: linear-gradient(96.27deg, #115743 12.79%, #4eb093 50.13%, #115743 107.19%);
}
.custom-primary.btn.btn-primary:hover {
  background: linear-gradient(96.27deg, #115743 12.79%, #4eb093 50.13%, #115743 107.19%);
}
button.custom-secondary.btn.btn-outline-secondary {
  max-height: 48px;
  border: 1.5px solid #545454;
  border-radius: 42px;
  padding: 2.5px 1.8rem 5px 4px !important;
  font-weight: bold;
  color: #fff;
}
button.custom-secondary.btn.btn-outline-secondary:hover {
  background-color: #545454;
}
.navbar-nav button.custom-secondary.btn.btn-outline-secondary {
  max-height: 48px;
  border: 3px solid rgb(119 126 144);
  border-radius: 42px;
  padding: 0.1rem 0.6rem 0.15rem 0.18rem !important;
  font-weight: 500;
  color: #fff;
  margin-left: 1rem;
  line-height: 38px;
  display: flex;
}
.mr-3,
.mx-3 {
  margin-right: 0.75rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 0.75rem !important;
}
.wallet-address-button-container .dropdown-item {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #ffffff;
  padding: 0.85rem !important;
}
.dropdown-item:active,
.dropdown-item:focus,
.wallet-address-button-container .dropdown-item:hover {
  background: rgb(49 49 49 / 40%);
  border-radius: 7.09371px;
}
.wallet-address-button-container .dropdown-item:hover i.fas.fa-circle.d-none.d-md-none {
  display: block !important;
  font-size: 7px;
  float: right;
  margin-left: auto;
}
a.dropdown-item img {
  margin-right: 7px;
  height: 22px;
  margin-left: -4px;
}
.wallet-address-button-container .dropdown-menu.show {
  padding: 20.6329px;
  background: rgb(0 0 0 / 54%);
  box-shadow: 0px 21.6329px 86.5317px -64.8987px rgb(31 47 70 / 74%);
  border-radius: 16px 0px 16px 16px;
  backdrop-filter: blur(32px);
  min-width: 220px;
  width: max-content;
  margin-right: 0px;
  margin-left: auto;
  margin-top: 0px;
  flex-basis: unset;
  flex-grow: unset;
  align-items: center;
  left: auto;
  right: 0;
}
.ProfileData {
  width: 100%;
}
.ProfileData h5.nickName {
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: #fcfcfd;
  margin-bottom: 0;
}
.navbar-nav a.pl-0 {
  padding-left: 0 !important;
}
.ProfileData {
  width: 100%;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(119, 126, 144, 0.3);
  box-shadow: 0px 24px 24px -16px rgba(15, 15, 15, 0.2);
}
.ProfileData p.addressLable {
  margin-bottom: 0;
}
.dropdown-toggle::after {
  vertical-align: 0.1em;
  margin-top: 18px;
}
.navbar-nav a.copyBtn {
  padding: 0 !important;
}

.dropdown-toggle::after {
  vertical-align: 0.1em;
  margin-top: 18px;
}
img.avatar {
  background: linear-gradient(96.27deg, #115743 12.79%, #4eb093 50.13%, #115743 107.19%);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin-right: 10px;
  margin-top: -1px;
  margin-left: -1px;
  object-fit: cover;
}
.addres-info {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 13px;
  font-size: 13px;
}
@media (max-width: 1200px) {
}

.navbar-nav button.btn.auth-btn.navbar-nav {
  padding: 0.85rem 1.5rem !important;
}
.navbar-nav a.btn.auth-btn.navbar-nav {
  padding: 0.85rem 1.5rem !important;
}
.header .header-top {
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 0 solid rgb(255 255 255 / 24%);
}
.btn-side-menu {
  height: calc(1.2em + 1.65rem + 5px);
  padding: 0 0.5rem !important;
  font-size: 1.08rem;
  line-height: 1.5;
  border-radius: 0.42rem;
}
.offcanvas-body .border-top {
  border-top: 1px solid #eee !important;
}
.d-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.navbar-expand-lg .offcanvas .offcanvas-body {
  flex-grow: 0;
  overflow-y: visible;
  padding: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.navbar-expand-lg .offcanvas .offcanvas-body {
  justify-content: right;
}
.navbar-expand-lg .navbar-nav:last-child {
  margin-right: 0;
  margin-left: auto;
}
.menuscroll nav.navbar {
  height: 80px;
}
.menuscroll.header-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 97;
  height: 130px;
  -webkit-animation: header-scroll-animation 0.5s ease 1;
  animation: header-scroll-animation 0.5s ease 1;
  background-color: #1d1d1dc4;
  -webkit-box-shadow: 0 10px 30px 0 rgb(82 63 105 / 8%);
  box-shadow: 0 10px 30px 0 rgb(82 63 105 / 8%);
}
.top-menue-title.dropdown-toggle.btn.btn-outline-secondary {
  padding: 0.85rem 1rem !important;
  border: 0;
  background: #eee0 !important;
  color: #fff;
}
.DropdownMenu {
  background: rgb(35 35 48 / 40%);
  box-shadow: 3px 5px 35px rgb(0 0 0 / 21%);
  -webkit-backdrop-filter: blur(32px);
  backdrop-filter: blur(32px);
  border-radius: 22px;
  padding: 20px;
  min-width: 220px;
}
.DropdownMenu .navbar-nav {
  display: block;
  margin-bottom: 1px;
}
.DropdownMenu .navbar-nav .nav-link.active {
  background: #0000001c;
  border-radius: 10px;
}
.DropdownMenu .nav-link:hover {
  background: #0000001c;
  border-radius: 10px;
}
.top-menue-title.dropdown-toggle.btn.btn-outline-secondary::after {
  margin-top: 0;
  margin-left: 5px;
}
.menuscroll {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 97;
  height: 80px;
  -webkit-animation: header-scroll-animation 0.5s ease 1;
  animation: header-scroll-animation 0.5s ease 1;
  background-color: #1d1d1dc4;
  -webkit-box-shadow: 0 10px 30px 0 rgb(82 63 105 / 8%);
  box-shadow: 0 10px 30px 0 rgb(82 63 105 / 8%);
}
#walletconnect-qrcode-modal a {
  color: #333;
}
.navbar-nav button.custom-secondary.btn.btn-outline-secondary.px-5 {
  padding: 0.1rem 1.6rem 0.15rem 1.6rem !important;
}
.switchFaild {
  background: rgb(118 61 22);
  box-shadow: 3px 5px 35px rgb(0 0 0 / 21%);
  -webkit-backdrop-filter: blur(32px);
  backdrop-filter: blur(32px);
  color: #fff;
  border-radius: 6px;
  padding: 20px 20px;
  font-size: 16px;
  display: flex;
  align-items: center !important;
}
.switchFaild span {
  font-size: 35px;
  margin-right: 1rem;
}
.switchFaild div p {
  margin-bottom: 0;
  color: #f2f2f2b5;
}
.switchFaild span img {
  width: 54px;
}
.switchFaild div p span {
  margin: 0;
  border-bottom: 1px solid;
}
@-webkit-keyframes header-scroll-animation {
  from {
    top: -70px;
  }
  to {
    top: 0;
  }
}
@keyframes header-scroll-animation {
  from {
    top: -70px;
  }
  to {
    top: 0;
  }
}
span.addressUser {
  color: #fff;
  padding: 0rem 0rem 0.85rem !important;
  font-size: 1rem;
  border-bottom: 1px solid #eeeeee40;
  display: block;
}
button.times-cancel i {
  font-size: 20px;
}
button.times-cancel {
  background: #eeeeee30;
  height: 40px;
  width: 40px;
  min-width: 40px;
  position: absolute;
  right: -15px;
  top: -15px;
}
@media (max-width: 767.98px) {
  .table-container {
    overflow: scroll;
  }
  .fees-data h6 {
    text-align: left;
  }
  .navbar-nav button.custom-secondary.btn.btn-outline-secondary {
    border: 0px solid rgb(119 126 144);
    padding: 0 !important;
  }
  .dropdown-toggle::after {
    display: none;
  }
  nav.navbar {
    width: auto;
    zoom: 0.9;
  }
  .header-top {
    zoom: 0.9;
  }
  .wallet-address-button-container .dropdown {
    position: initial;
  }
  .wallet-address-button-container .dropdown-menu.show {
    margin-left: 15%;
    margin-top: -12px;
    flex-basis: unset;
    flex-grow: unset;
    align-items: center;
    min-width: 280px;
  }
  .container-fluid {
    padding: 0 30px;
  }
  .navbar-collapse {
    padding: 28.3932px 21.6329px;
    background: linear-gradient(96.27deg, #115743 12.79%, #4eb093 50.13%, #115743 107.19%);
    box-shadow: 0px 21.6329px 86.5317px -64.8987px rgb(31 47 70 / 74%);
    border-radius: 32.4494px 0px 32.4494px 32.4494px;
    min-width: 235px;
    margin-right: 0px;
    margin-left: auto;
    margin-top: -5px;
    flex-basis: unset;
    flex-grow: unset;
    align-items: center;
  }
  .navbar-collapse .navbar-nav a {
    padding: 15px !important;
    color: #fff;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-link {
    color: #ffffff !important;
  }
  .navbar-collapse a.nav-link.active {
    background-color: #ff4a41;
    border-radius: 12.1685px;
  }
  .navbar-nav .dropdown .nav-link.active {
    color: #fff;
  }
  button.custom-secondary.btn.btn-outline-secondary {
    border: 2px solid #545454;
    padding: 2.5px 4px 5px 4px !important;
    margin-right: 0;
    margin-left: auto;
  }
  img.avatar {
    margin-right: 0;
    background: linear-gradient(96.27deg, #115743 12.79%, #4eb093 50.13%, #115743 107.19%);
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin-top: -3px;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-link i {
    color: #fff;
    font-size: 10px;
    float: right;
    margin-top: 7px;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-link.active i {
    display: block !important;
  }
  span.text-italic {
    color: #2832a1;
    font-size: 14px;
    font-weight: 600;
    font-style: italic;
    letter-spacing: 1.5px;
  }
  .offcanvas-body .text-white {
    color: #3f4254 !important;
  }
  .data-mobile .navi-item.mt-20.progres {
    margin-top: 2.5rem !important;
  }
  .data-mobile a.btn.btn-sm {
    padding: 0.5rem 1.25rem !important;
  }
  .offcanvas-body .navbar-nav {
    zoom: 0.9;
  }
  .nav-SideMenu.navbar-nav {
    padding: 0.85rem 1.5rem !important;
  }
  body .navbar-nav .btn-side-menu {
    width: fit-content;
  }
  .offcanvas {
    max-width: 90%;
  }
  .header-token.container .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    background: #012d68;
  }
  .offcanvas-body {
    padding: 0px;
  }
  a.nav-link.active {
    background-color: #f3f6f9;
    color: #2832a1;
  }
  .header-top a.nav-link.active {
    background: #0000001c;
    border-radius: 10px;
  }
  a.nav-link.active .menu-text {
    color: #2832a1;
  }
  body .navbar-nav button.custom-secondary.btn.btn-outline-secondary.px-5 {
    padding: 6.5px 10px 6.5px 10px !important;
    margin-left: 0.5rem !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .wallet-address-button-container .dropdown {
    position: initial;
  }
  .wallet-address-button-container .dropdown-menu.show {
    margin-left: 55%;
    margin-top: -10px;
    min-width: 280px;
  }
  .titletable {
    display: flex;
    width: max-content;
  }
  .rights p {
    text-align: center !important;
    border-top: 1px solid #eeeeee4f;
    padding-top: 20px;
  }
  .container-fluid .min-height-100per.Team.GetTouch {
    margin-top: 0rem;
    min-height: auto;
    margin-bottom: 5rem;
  }
  .custom-margining {
    margin-top: -24rem;
  }
  .itemslide img {
    margin-bottom: -40px;
    margin-top: -110px;
    width: 80%;
  }
  .itemslide {
    width: 98%;
  }

  .container-fluid {
    padding: 0 30px;
  }
  .navbar-collapse {
    padding: 28.3932px 21.6329px;
    background: linear-gradient(96.27deg, #115743 12.79%, #4eb093 50.13%, #115743 107.19%);
    box-shadow: 0px 21.6329px 86.5317px -64.8987px rgb(31 47 70 / 74%);
    border-radius: 32.4494px 0px 32.4494px 32.4494px;
    min-width: 235px;
    margin-right: 0px;
    margin-left: 55%;
    margin-top: -5px;
  }
  button.custom-secondary.btn.btn-outline-secondary.px-5 {
    padding: 6.5px 10px 6.5px 10px !important;
  }
}
@media (max-width: 991.98px) {
  .navbar-collapse .navbar-nav a {
    padding: 15px !important;
    color: #fff;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-link {
    color: #ffffff !important;
  }
  .navbar-collapse a.nav-link.active {
    background-color: #ff4a41;
    border-radius: 12.1685px;
  }
  button.custom-secondary.btn.btn-outline-secondary {
    border: 2px solid #545454;
    padding: 2.5px 4px 5px 4px !important;
    margin-right: 0;
    margin-left: auto;
  }
  .custom-secondary .disconnectImg {
    margin-top: 0 !important;
  }
  img.avatar {
    margin-right: 0;
    background: linear-gradient(96.27deg, #115743 12.79%, #4eb093 50.13%, #115743 107.19%);
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin-top: -3px;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-link i {
    color: #fff;
    font-size: 10px;
    float: right;
    margin-top: 7px;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-link.active i {
    display: block !important;
  }
  span.text-italic {
    color: #2832a1;
    font-size: 14px;
    font-weight: 600;
    font-style: italic;
    letter-spacing: 1.5px;
  }
  .offcanvas-body .text-white {
    color: #3f4254 !important;
  }
  .data-mobile .navi-item.mt-20.progres {
    margin-top: 2.5rem !important;
  }
  .data-mobile a.btn.btn-sm {
    padding: 0.5rem 1.25rem !important;
  }
  .offcanvas-body .navbar-nav {
    zoom: 0.9;
  }
  .nav-SideMenu.navbar-nav {
    padding: 0.85rem 1.5rem !important;
  }
  body .navbar-nav .btn-side-menu {
    width: fit-content;
  }
  .offcanvas {
    max-width: 90%;
  }
  .header-token.container .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    background: #012d68;
  }
  .offcanvas-body {
    padding: 0px;
  }
  a.nav-link.active {
    background-color: #f3f6f9;
    color: #2832a1;
  }
  .header-top a.nav-link.active {
    background: #0000001c;
    border-radius: 10px;
  }
  a.nav-link.active .menu-text {
    color: #2832a1;
  }
  button.custom-secondary.btn.btn-outline-secondary {
    border: 2px solid #545454;
    padding: 2.5px 4px 5px 4px !important;
    margin-right: 0;
    margin-left: auto;
  }
  img.avatar {
    margin-right: 0;
    background: linear-gradient(96.27deg, #115743 12.79%, #4eb093 50.13%, #115743 107.19%);
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin-top: -3px;
  }
  button.custom-secondary.btn.btn-outline-secondary.px-5 {
    padding: 6.5px 10px 6.5px 10px !important;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    height: auto !important;
  }
  .header-top {
    height: 100px;
  }
}
@media (min-width: 992px) and (max-width: 1070px) {
  .header-menu .menu-nav > .menu-item > .menu-link {
    padding: 0.85rem 0.5rem;
    font-size: 12px;
  }
  img.logo {
    height: 30px !important;
  }
}

@media (min-width: 992px) and (max-width: 1400px) {
  .top-menue-title.dropdown-toggle.btn.btn-outline-secondary,
  body .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0.85rem 0.5rem !important;
    font-size: 12px;
  }
  .navbar-expand-lg .navbar-nav {
    margin-right: 0.3rem;
  }
  span.beta-span {
    line-height: 30px;
    font-size: 14px;
    margin-left: 10px;
    top: 3px;
  }
}

.navbar-brand {
  width: auto;
  text-align: left;
}

.offcanvas.offcanvas-end {
  left: 0;
  right: unset;
}

.special-link {
  color: #32ffff !important;
  font-size: 14px;
  font-style: italic;
}

.auth-btn,
.mobile-auth-btn {
  color: #fff !important;
  background-color: #fff;
  border: 1px solid #fff;
}
span.logoAndTitle.navbar-brand {
  /* display: flex; */
}
span.logoAndTitle.navbar-brand a {
  display: inline-block;
}
span.logoAndTitle.navbar-brand span {
  color: #fff;
  font-size: 16px;
  margin-left: 0px;
  letter-spacing: 1px;
  font-weight: 600;
}
/* .auth-btn:hover {
    background-color: transparent;
} */

.auth-btn span,
.mobile-auth-btn span {
  color: #00122d !important;
}

.auth-btn:focus span,
.auth-btn:active span,
.auth-btn:hover span,
.mobile-auth-btn:focus span,
.mobile-auth-btn:active span,
.mobile-auth-btn:hover span {
  color: #32ffff !important;
}

.auth-btn:focus,
.auth-btn:active,
.auth-btn:hover,
.mobile-auth-btn:focus,
.mobile-auth-btn:active,
.mobile-auth-btn:hover {
  box-shadow: none !important;
  border: 1px #fff solid !important;
  background-color: transparent !important;
}

.navbar-nav a {
  padding: 0.85rem 1.5rem;
}

.navbar-nav button {
  padding: 0.55rem 1rem !important;
}

.secondery-logo {
  padding-top: 1.25rem;
}

.mobile-auth-btn {
  display: none;
}

.mobile-logo {
  display: none;
}
button.navbar-toggler i {
  color: #fff;
  font-size: 35px;
}
.navbar-toggler:focus {
  box-shadow: unset;
}
.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: #fff;
}
img.logo-mobile {
  width: 85px;
}
@media (max-width: 991.5px) {
  /* .navbar-brand {
        display: none !important;
    } */

  .hero-section-box {
    padding: 2.5rem 0 0;
  }
  /* .menuscroll {
    position: relative !important;
  } */

  .navbar {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .menuscroll .navbar {
    padding: 0 30px;
  }
  .navbar-nav .auth-btn {
    display: none;
  }

  .mobile-auth-btn {
    display: block !important;
  }

  .mobile-logo {
    display: block;
  }

  .offcanvas-start {
    width: 21rem !important;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    color: #3f4254 !important;
  }
  .header-top .navbar-expand-lg .navbar-nav .nav-link {
    color: #fff !important;
  }
  .max-h-30px {
    max-height: 30px;
  }
}

.navbar-expand-lg .offcanvas .offcanvas-body {
  justify-content: right;
}

.ProfileDataBalance {
  display: flex;
  color: #fff;
  align-items: center;
  /* box-shadow: 0px 24px 24px -16px rgba(15, 15, 15, 0.2); */
  background: #17161e00;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 5px;
}
img.networkIcon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}
p.LableName {
  color: #777e90;
  font-size: 14px;
  margin-bottom: 5px;
  margin-bottom: -2px;
}
p.LableBalance {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #fcfcfd;
  margin-bottom: 0;
}
.border-custome {
  border-bottom: 1px solid rgba(119, 126, 144, 0.3);
  padding-top: 10px;
  padding-bottom: 15px;
  margin-bottom: 10px;
  box-shadow: unset;
  box-shadow: 0px 24px 24px -16px rgba(15, 15, 15, 0.2);
}
.navbar-nav a.nav-link.menuBottom {
  width: 100%;
  padding-left: 0 !important;
  color: #777e91;
  display: flex;
  align-items: center;
}
.navbar-nav a.nav-link.menuBottom img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
img.logo {
  height: 30px;
}

.EventSlider button.slick-arrow.slick-prev {
  position: absolute;
  top: 42%;
  right: auto;
  left: -5%;
  z-index: 1;
}
.EventSlider button.slick-arrow.slick-next {
  position: absolute;
  top: 42%;
  right: -5%;
  left: auto;
  z-index: 1;
}
img.eventsImg {
  object-fit: cover;
  border-radius: 20px;
  min-height: 100%;
  background: #545454;
  display: block;
  max-height: 460px;
  margin-left: 1px;
}
.TrendingSection {
  margin-top: 2.5rem;
}
.TrendingSection h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 1rem;
}
.TrendCard {
  margin-bottom: 1.5rem;
}
.AllEvents {
  margin-bottom: 1.5rem;
}
.EventSpecail {
  background: #3b3b3b;
  border-radius: 20px;
  padding: 4rem 4.5rem;
  height: 100%;
}
.dateInfo h4 {
  text-align: center;
  padding: 12px 12px;
  width: 65px;
  height: 65px;
  font-weight: 600;
  font-size: 18px;
  background: linear-gradient(96.27deg, #115743 12.79%, #4eb093 50.13%, #115743 107.19%);
  border-radius: 10px;
}
.titleAndDesc h4 {
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 5px;
  height: 43px;
  overflow: hidden;
}
.slick-list .titleAndDesc h4 {
  height: auto;
  overflow: unset;
}
.titleAndDesc p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ced3df;
}
.titleAndDesc p span {
  font-weight: 600;
  font-size: 16px;
  color: #ff4a41;
}
.AddCalaender button {
  background: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ff4a41;
  padding: 0;
  border: 0;
}
.SatetAt {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #ced3df;
}
.pr-7px {
  padding-right: 6px;
}
.SatetAt img {
  margin-right: 5px;
  max-height: 20px;
  max-width: 20px;
  margin-top: -3px;
}
span.lable-dark img {
  margin-right: 7px;
  height: 17px;
}
span.lable-dark {
  display: flex;
  border-radius: 8px;
  background: #545454;
  padding: 7px 10px 6px;
  font-weight: 400;
  font-size: 14px;
  margin-right: 5px;
  color: #ffffff;
  width: 100px;
}
.actionLable {
  display: flex;
  float: right;
}
span.lable-LightBtn img {
  height: 34px;
}
.ActionJump {
  display: flex;
}
img.shareBtn {
  height: 46px;
  width: 46px;
  cursor: pointer;
  border-radius: 50%;
}
img.shareBtn:hover {
  background: linear-gradient(96.27deg, #115743 12.79%, #4eb093 50.13%, #115743 107.19%);
  box-shadow: rgb(159 68 247 / 24%) 0px 4px 12px;
}

.ActionJump .btnCustome.btn.btn-outline-secondary {
  font-weight: 600;
  font-size: 16px;
  padding: 10px;
}
span.lable-LightBtn {
  cursor: pointer;
}
span.lable-LightBtn:hover {
  box-shadow: rgb(255 91 91 / 78%) 0px 4px 12px;
  border-radius: 22px;
}
.containerTrend {
  border: 1px solid #545454;
  border-radius: 10px;
  position: relative;
  margin-bottom: 1.5rem;
}
span.LableSale {
  background: linear-gradient(96.27deg, #115743 12.79%, #4eb093 50.13%, #115743 107.19%);
  border-radius: 48px;
  font-size: 15px;
  padding: 1px 11px 1px;
  position: absolute;
  left: 43px;
  top: 15px;
}
img.TrendImg {
  height: 130px;
  object-fit: cover;
  border-radius: 10px 0px 0 10px;
  margin-left: 0px;
  background: #545454;
  min-height: 100%;
  min-width: 100%;
}
img.TrendImgAll {
  height: 179px;
  object-fit: cover;
  border-radius: 20px 20px 0 0px;
  min-height: 100%;
  background: #545454;
  display: block;
}
.px-8-22 {
  padding-left: 8px;
  padding-right: 22px;
}
.pr-22px {
  padding-right: 22px;
}
.pr-22px .titleAndDesc {
  height: 48px;
  overflow: hidden;
}
.pr-22px .titleAndDesc h4 {
  line-height: 24px;
}
.px-35px {
  padding-left: 35px;
  padding-right: 35px;
}
.containerTrendAll {
  border: 1px solid #545454;
  border-radius: 20px;
  position: relative;
  margin-bottom: 1.5rem;
  background: #2b2b2b;
}
span.LableSaleAll {
  background: linear-gradient(96.27deg, #115743 12.79%, #4eb093 50.13%, #115743 107.19%);
  border-radius: 48px;
  font-size: 15px;
  padding: 1px 11px 1px;
  position: absolute;
  right: 35px;
  top: 25px;
}
span.LableSaleAllWithe {
  left: 35px;
  text-transform: uppercase;
  background: #fff;
  color: #333;
  font-weight: 500;
  border-radius: 48px;
  font-size: 15px;
  padding: 1px 11px 1px;
  position: absolute;
  top: 25px;
}
.modal-body .dateInfo h4 {
  text-align: center;
  padding: 15px 20px;
  width: 90%;
  height: auto;
  font-weight: 600;
  font-size: 18px;
  background: linear-gradient(96.27deg, #115743 12.79%, #4eb093 50.13%, #115743 107.19%);
  border-radius: 10px;
}
.align-items-center {
  align-items: center;
  display: flex;
}
.EventModal .modal-content {
  background: #3b3b3b;
  border-radius: 30px;
  padding: 1.7rem 4rem !important;
}
.EventModal .modal-header {
  position: absolute;
  z-index: 9;
  top: -5px;
  right: -5px;
}
.SatetAt.ModalStart img {
  margin-top: -7px;
}
.ModalStart h4 {
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 5px;
}
.infoMore {
  margin-left: 25px;
}
.infoMore a {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ff4a41;
  margin: 0px 0 10px;
}
.infoMore p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
img.arrowRight {
  height: 22px;
  width: 22px;
  margin-left: 10px;
  margin-top: -4px;
}
.CheckboxCategories {
  color: #545454 !important;
}
.CategoriesInput {
  color: #a3a3a3;
  margin-bottom: -5px;
}
.CheckboxCategories.Mui-checked {
  color: #e34038 !important;
}
.react-datetime-picker__wrapper {
  border: 1px solid #545454 !important;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  font-size: 14px;
  border-radius: 0.375rem;
  padding: 0.65rem 1rem;
}

.react-datetime-picker__wrapper input {
  color: #ffffff8f;
}
.react-datetime-picker__wrapper select {
  color: #ffffff8f;
  background: #2b2b2b;
  outline: unset;
}
body input.react-datetime-picker__inputGroup__input:focus-visible {
  outline: 0;
}
.react-datetime-picker__button svg {
  stroke: #fff;
}
.avatarImage .eventImage .image-input .image-input-wrapper {
  width: 100%;
  height: 250px;
  background: #3b3b3b;
  border-radius: 16px;
  background-position: center;
}
.eventImage .image-input {
  width: 100%;
  height: auto;
}
.eventImage .btn.btn-icon.btn-xs {
  height: 100%;
  width: 100%;
  border-radius: 16px;
  background: #3b3b3b12;
  border-color: #3b3b3b;
}
.eventImage .btn.btn-icon i {
  font-size: 28px;
  color: #ced3df;
  /* color: #c8cad1; */
}
.eventImage .image-input [data-action="change"] {
  right: 0;
  top: 0;
}
.upload-file {
  display: grid;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ced3df;
  /* color: #c8cad1; */
}
.upload-file span {
  margin-top: 0.7rem;
}
.upload-file span {
  margin-top: 0.7rem;
}
.EventSlider ul.slick-dots {
  display: none !important;
}
span.LiveSpan {
  color: #5cb937;
  position: relative;
  width: 100%;
}
span.todaySpan {
  color: #ff4a41;
  font-size: 14px;
  line-height: 18px;
  padding-top: 0px;
  padding-right: 15px;
}
.EventsCardsInFillter span.todaySpan {
  color: #ff4a41;
  font-size: 15px;
  line-height: 28px;
  padding-top: 0px;
}
.circleLive {
  position: absolute;
  left: 49%;
  top: 45%;
  transform: translateX(-50%) translateY(-50%);
  width: 20px;
  height: 20px;
}
.slick-slide .circleLive {
  left: 40%;
  top: 45%;
}
.circleLive:before {
  content: "";
  position: relative;
  display: block;
  width: 250%;
  height: 250%;
  box-sizing: border-box;
  margin-left: -75%;
  margin-top: -75%;
  border-radius: 45px;
  background-color: #75daad;
  animation: pulse 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}
.circleLive:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #75daad;
  border-radius: 50px;
  animation: circle 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}
@keyframes circle {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
.CategoriesFillter .CategoriesInput span {
  font-size: 18px;
}
.CategoriesFillter .CategoriesInput span svg {
  width: 1.2em;
  height: 1.2em;
}
.CategoriesFillter .CategoriesInput {
  margin-right: 0px;
  margin-left: 0;
  width: 100%;
}
.CategoriesFillter h6.nickName {
  font-size: 18px;
  font-weight: 400;
}
.borderTopPadding {
  border-top: 1px solid #545454;
  padding-top: 2rem;
  margin-top: 2rem;
}
body .react-datetime-picker__button svg {
  stroke: #ffffff3b;
}
body .react-datetime-picker__inputGroup__divider,
body .react-datetime-picker__inputGroup__leadingZero {
  display: inline-block;
  color: #ffffff8f;
}
body .react-datetime-picker__button {
  border: 0;
  background: transparent;
  padding: 0px 6px;
}
.pointer {
  cursor: pointer;
}
.titleAndDesc p span.categoryName {
  color: #ced3df;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.modal-body .titleAndDesc h4 {
  height: auto;
}
.ActionJump .btnCustome.btn.btn-outline-secondary:disabled {
  opacity: 0.4;
}
.containerTrendAll:hover {
  border: 1px solid #e340384f;
}
.columns_2 {
  columns: 2 !important;
  -webkit-columns: 2 !important;
  -moz-columns: 2 !important;
  display: block !important;
}
.columns_3 {
  columns: 3 !important;
  -webkit-columns: 3 !important;
  -moz-columns: 3 !important;
  display: block !important;
}
.columns_3 label.CategoriesInput {
  margin-right: 0px;
  margin-left: 0;
  width: 100%;
}
.calender .react-datetime-picker__wrapper {
  padding: 5px 5px;
  font-size: 12px;
}
body .calender .react-datetime-picker__button {
  border: 0;
  background: transparent;
  padding: 0px 2px;
}
.PaginationCountOfData {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #ced3df;
}
.PaginationOptionsOfData.pagination {
  font-size: 12px;
}
.PaginationOptionsOfData .page-link {
  background: #f000;
  border: 0;
  font-size: 12px;
  color: #ced3df;
  line-height: 22px;
  height: 32px;
}
.PaginationOptionsOfData .activeButton a.page-link {
  color: #fff;
  width: 32px;
  height: 32px;
  background: #545454;
  border-radius: 50%;
  line-height: 22px;
}
.PaginationOptionsOfData li.inactiveButton.page-item .page-link {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  line-height: 22px;
}
.PaginationOptionsOfData .page-link:focus {
  box-shadow: unset;
}
.PaginationOptionsOfData .page-link:hover {
  color: #fff;
}
.CategoriesFillter .bg-border-gradiant.contact-form-bg .form-control.form-control-solid {
  border: 0px solid #545454 !important;
  padding-left: 0;
  font-weight: 300;
  letter-spacing: 0.2px;
}
.CategoriesFillter .bg-border-gradiant.contact-form-bg .form-control.form-control-solid:focus {
  outline: 0;
  box-shadow: unset;
}
.SearchCustomInput.input-group.bg-border-gradiant.contact-form-bg {
  border: 1px solid #545454;
  border-radius: 63px;
}
.SearchCustomInput.input-group.bg-border-gradiant.contact-form-bg span {
  height: 43px;
  padding: 15px 15px 15px 25px;
}
img.searchIcon {
  width: 18px;
  height: 18px;
}
.calender label {
  color: #a3a3a3;
}
.react-calendar.Selectdatetime {
  /* background: #353340;
  border: 1px solid #545454; */
  border-radius: 15px;
  padding: 15px;
}
.react-calendar.Selectdatetime button {
  margin: 0;
  border: 0;
  outline: none;
  border-radius: 6px;
}
/* .react-calendar.Selectdatetime .react-calendar__month-view__days__day--weekend {
  color: #ff4a41;
} */
/* .react-calendar.Selectdatetime .react-calendar__tile--now {
  background: #6bd796;
} */
body .react-datetime-picker__inputGroup__input:invalid {
  background: rgb(23 22 30);
  text-align: center;
  border: 0px;
  outline: unset;
}
body .react-datetime-picker__clock {
  background-color: #3b3b3b;
  border: thin solid #a0a0961f;
  border-radius: 15px;
}
.SelectTime .react-clock__face {
  border: 1px solid #3b3b3b;
}
.SelectTime .react-clock__mark__body {
  background-color: #cdcdcd;
}
.SelectTime .react-clock__hand__body {
  background-color: #bbbbbb;
}
.OwnedStyle span.MuiSwitch-track {
  background-color: #7a7a9f;
}
.OwnedStyle .MuiSwitch-switchBase {
  /* color: #7221cd; */
}
.OwnedStyle .MuiSwitch-switchBase.Mui-checked {
  color: #7221cd;
}
.OwnedStyle label .MuiSwitch-root .Mui-checked + .MuiSwitch-track {
  background-color: #e34038ad;
}
.OwnedStyle label {
  margin-right: 0;
  margin-left: auto;
  margin-bottom: 0px;
  padding-right: 7px;
}
.resetDateImg {
  cursor: pointer;
  position: absolute;
  right: 43px;
  margin-top: 10px;
  opacity: 0.2;
  width: 21px;
}
.resetDateImg:hover {
  opacity: 0.7;
}
.titleAndDesc p span.categorySpan {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ced3df;
}
/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .EventSpecail {
    padding: 2rem 2rem;
  }
  img.eventsImg {
    height: 225px !important;
  }
  .slick-slide.slick-active.slick-current {
    background: #3b3b3b;
    border-radius: 20px;
  }
  .pr-7px {
    padding-right: calc(1.5rem * 0.5);
  }
  .py-2.pr-8-22.col-lg-12 {
    padding-left: 25px;
    padding-right: 25px;
  }
  .px-8-22 {
    padding-left: 25px;
    padding-right: 25px;
  }
  img.TrendImg {
    border-radius: 10px 10px 0 0px;
  }
  .ActionJump .btnCustome.btn.btn-outline-secondary {
    font-size: 14px;
  }
  ul.PaginationOptionsOfData.pagination {
    width: 100%;
  }
  .columns_3 {
    columns: 2 !important;
    -webkit-columns: 2 !important;
    -moz-columns: 2 !important;
    display: block !important;
  }
}

/* // Medium devices (tablets, less than 992px) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .EventSpecail {
    padding: 2rem 2rem;
  }
  body {
    zoom: 0.9;
  }
  .EventsCardsInFillter {
    zoom: 0.9;
  }
  .ActionJump .btnCustome.btn.btn-outline-secondary {
    font-size: 14px;
  }
}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* // X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
}

.item img {
  height: 255px;
  margin-bottom: -50px;
}
.text-of-slider {
  text-align: center;
}
.text-of-slider h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 15px;
}
.text-of-slider span {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ff4a41;
}
.text-of-slider p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #969696;
  margin-top: 10px;
}
ul.social-ul {
  padding-left: 0;
  display: flex;
}
ul.social-ul li {
  display: block;
  width: 36px;
  height: 36px;
  text-align: center;
  margin-right: 5px;
  border: 4px solid rgb(143 143 143);
  border-radius: 50%;
  line-height: 30px;
  color: rgb(143 143 143);
}
ul.social-ul li i {
  color: rgb(143 143 143);
}
ul.social-ul li:hover {
  border: 4px solid #e34038;
}
ul.social-ul li:hover i {
  color: #ff4a41;
}
.Team h1 {
  font-weight: 700;
  font-size: 64px;
  line-height: 72px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 3rem;
}
.item:hover {
  background: rgba(78, 78, 97, 0.05);
  cursor: pointer;
}

.item:hover .admin-table>:not(caption)>*>* {
  background-color: rgba(78, 78, 97, 0.05) !important;
}

.itemslide {
  border: 0px solid #fff;
  z-index: 2;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: block;
  padding: 25px;
}
.slider-theme .slick-list {
  padding-top: 7rem;
}
.itemslide img {
  display: block;
  margin-bottom: -40px;
  margin-top: -130px;
}
.itemslide .text-of-slider p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #969696;
  margin-top: 10px;
}
.itemslide .text-of-slider span {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.itemslide .text-of-slider h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 10px;
}
.slider-theme .slick-prev,
.slider-theme .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: -4rem;
}
.slider-theme .slick-next {
  right: 13px;
}
.slider-theme .slick-prev {
  left: auto;
  right: 55px;
}
button.slick-arrow.slick-next {
  display: block;
  width: 36px;
  height: 36px;
  transform: rotate(90deg);
  border-radius: 50%;
  border: 1px solid #545454;
  text-align: center;
  padding: 3px 0px 15px;
}
.slider-theme .slick-prev {
  display: block;
  display: block;
  width: 36px;
  height: 36px;
  transform: rotate(-90deg);
  border-radius: 50%;
  border: 1px solid #545454;
  text-align: center;
  padding: 3px 0px 15px;
}
.slick-prev:before,
.slick-next:before {
  content: "\2191" !important;
}
@media (max-width: 991.98px) {
  .min-height-100per.Team {
    padding-top: 0;
  }
  .Team h1 {
    font-size: 36px;
    line-height: 36px;
    text-align: left;
    margin-bottom: 2rem;
  }
}
@media (min-width: 500px) and (max-width: 767.98px) {
  .itemslide {
    z-index: 2;
    background: unset;
    background-repeat: no-repeat;
    background-size: 100% 99%;
    display: block;
    padding: 25px;
    border: 1px solid #e340382b;
    border-radius: 15px;
  }
  .min-height-100per.Team {
    margin-top: -3rem;
  }
}
