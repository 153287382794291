.tab-Pages {
  border-bottom: 2px solid #4f525f;
}
.nav-tabs .nav-link.active {
  background: #f000;
  border: 0;
  border-bottom: 4px solid #e34038;
  color: #ff4a41;
}
.nav-tabs .nav-link {
  padding: 10px 26px;
  color: #ced3df;
  font-size: 15px;
  border: 0px;
}
.tab-content {
  font-size: 15px;
  font-weight: 300;
  line-height: 24px;
}
.Active-check .form-check-input {
  background-color: #fff0;
  border: 3px solid rgba(119, 126, 144, 1);
  margin-top: -2px;
  padding: 9px;
}
.Active-check label.form-check-label {
  margin-bottom: 0;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}
button.custom-Sort {
  padding: 0;
  border: 0;
  color: #fff;
  display: inline-flex;
}
/* .btn:first-child:active, */
button.custom-Sort:hover {
  background: #978c8c00;
  border: 0;
}
button.custom-Sort:after {
  display: none;
}
button.custom-Sort span.lable {
  margin-bottom: 0;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 400;
}
.SortMenu .dropdown-menu.show {
  background: rgba(35, 35, 48, 0.36);
  box-shadow: 3px 5px 35px rgb(0 0 0 / 21%);
  backdrop-filter: blur(23px);
  border-radius: 12px;
  padding: 15px;
}
.SortMenu .dropdown-item {
  color: #ffffff;
  margin-bottom: 10px;
  font-size: 15px;
}
.SortMenu .dropdown-item:last-child {
  margin-bottom: 0px;
}
svg.iconMenu {
  width: 20px;
  height: auto;
  margin-right: 10px;
}
.SortMenu .dropdown-item:hover {
  background: #f000;
  color: #ff4a41;
}
.filter_items {
  display: inline-flex;
  float: right;
}
.tab-content p {
  letter-spacing: 0.2px;
}
.Active-check {
  margin-left: 20px;
}
.icons {
  display: grid;
  margin-right: 13px;
  color: #ced3df;
}
.icons i.fas.fa-caret-up {
  height: 9px;
  margin-top: -3px;
}
.Active-check {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  display: flex;
  cursor: pointer;
}
.acitveAsc {
  color: #5cb937;
}
p.firstline strong span {
  color: #fa6109;
}
.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}
button[disabled] {
  opacity: 0.6;
}
button.likebtn {
  border: 0;
  background: #f000;
  padding: 0;
  margin: 0 -5px;
}
.cardProposal {
  /* background-image: url(../../../assets/ProposalCARDS.svg);
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: contain; */

  background-color: #3b3b3b;
  border: 3px solid #3b3b3b;
  border-radius: 20px;
  min-height: 286px;
  padding: 30px 30px 40px;
  font-size: 15px;
}
p.firstline {
  color: #ced3df;
  font-weight: 400;
}
p.firstline strong {
  color: #ff4a41;
}
p.secondline {
  color: #ced3df;
  font-weight: 400;
}
p.secondline strong {
  color: #ffffff;
}
.pl-0 {
  padding-left: 0 !important;
}
img.LikeBtn {
  width: 50px;
}
.range .progress {
  background: #fa6109;
  overflow: inherit;
  height: 9px;
}
.range .progress-bar {
  background: #5cb937;
  position: relative;
  overflow: initial;
  border-radius: 4px;
}
img.rangImage {
  width: 34px;
  height: 34px;
  position: absolute;
  right: -17px;
}
.cardProposalNoData {
  background-color: #3b3b3b;
  border: 3px solid #3b3b3b;
  border-radius: 20px;
  padding: 30px 30px 30px;
  font-size: 15px;
}
img.TELEVERSENFT {
  z-index: 1;
  position: relative;
}
h2.supply {
  z-index: 2;
  position: relative;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgb(7 25 27) 0%,
    rgb(58 36 13) 50%,
    rgb(150 85 19) 100%
  );
  width: 80px;
  height: 72px;
  top: 1rem;
  padding: 0px;
  margin-left: auto;
  font-size: 32px;
  border-radius: 15px;
}
.supply-img h4.AiTalentNFT {
  text-align: left;
  margin-top: -6rem;
}
.supply-img h3.VotePower {
  text-align: left;
}
img.Vote {
  margin-top: -20rem;
}
.inactive.dnoneForInactive {
  display: none;
}
img.bg66 {
  border-radius: 20px;
}
/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  h2.supply {
    top: -12rem;
    margin-right: 2rem;
    margin-left: auto;
    font-size: 32px;
    zoom: 0.75;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .cardProposal {
    max-height: unset;
  }
  h2.supply {
    top: -9rem;
    margin-right: 1rem;
    font-size: 25px;
    zoom: 0.8;
  }
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .mobile-size {
    zoom: 0.9;
  }
  .cardProposal {
    max-height: unset;
    padding: 30px 20px 40px;
  }

  h2.supply {
    top: -11rem;
    margin-right: 4rem;
    font-size: 25px;
  }
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .mobile-size {
    zoom: 0.85;
  }
  .cardProposal {
    max-height: unset;
    padding: 30px 20px 40px;
  }
  h2.supply {
    top: -13.5rem;
    margin-right: 4.5rem;
    font-size: 25px;
  }
}
