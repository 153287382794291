.h-screen {
  height: calc(100vh - 200px);
}

.w-screen {
  width: 100vw;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #3b3b3b;
  backdrop-filter: blur(23.75px);
  border-radius: 20px;
  padding: 0;
  border: 2px solid rgb(255 255 255 / 11%);
  min-height: 298px;
  padding: 15px 15px 15px 0;
  margin: 30px 20px;
}



@media (max-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }
  .md-wrap {
    flex-wrap: wrap;
  }
}

.text-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
}

.item table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.scrollx-auto {
  overflow-x: auto;
}
.admin-table>:not(caption)>*>* {
  background-color: #3b3b3b !important;
}