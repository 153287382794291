.what-televerse{
    height: 100vh;
 }
.nft-img {
background: radial-gradient(50% 50% at 50% 50%, #9E44F6 0%, rgba(40, 42, 59, 0) 100%);
background-position: top ;
}

.what-is-televerse-text {
    margin-top: 70px;
}
.what-is-televerse-head {
font-family: 'Sora';
font-weight: 700;
font-size: 64px;
line-height: 72px;
text-align: justify;
color: #FFFFFF;
}

.what-is-televerse-description {
max-width: 526px;
font-family: 'Sora';
font-weight: 400;
font-size: 18px;
line-height: 28px;
color: #A3A3A3;
margin : 29px 0px 0px 110px;

}

@media (max-width: 991px) {
    .what-is-televerse-description {
        margin : 29px 0px 0px 0px;
        width: 100%;
    }
    .what-is-televerse-head{
        font-size: 40px;
        line-height: 48px;
        margin-top: 3rem;
        word-spacing: 0;

    }
    .what-is-televerse-description {
        font-size: 16px;
        line-height: 24px;
        max-width: 100%;
        margin-top: 1rem;
        width: 100%;
    }
    .what-is-televerse-img {
        display: flex;
        justify-content: center;
        }

    }