.powered-section {
    height: 100vh;
}

.powered-text {
max-block-size: 417.13px;
font-family: 'Sora';
font-weight: 700;
font-size: 64px;
line-height: 72px;
color: #FFFFFF;
}

.portals-img-mob {
    display: none;
}


@media (max-width: 992px) {
.powered-text {
    font-size: 3rem;
    line-height: 54px;
    display: flex;
    justify-content: center;
}
.powered-section {
    height: 100%;
    margin: 6rem auto;
}


}

@media (max-width: 767px) {
   
.portals-img {
    display: none;
}
.portals-img-mob {
    display: block;
}
.powered-img {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}
}

@media (max-width: 575px) {
.powered-text {
    font-size: 2rem;
    line-height: 36px;
    display: flex;
    justify-content: center;
}
}