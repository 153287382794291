.what-is-ds-title {
  margin-bottom: 24px;
  margin-top: 50px;
}

.custom-live-section {
  height: 100%;
  background: url(../../../../assets/ds-rocket.png);
  background-position: 63% 30%;
  background-repeat: no-repeat;
  background-size: cover;
}
img.rocketRight {
  margin-bottom: -4rem;
  /* margin-right: -3rem; */
  width: 130%;
  right: 2rem;
  position: relative;
  animation: vertical-shaking 2s ease-in-out infinite;
}
p.moreThanMaxBy {
  font-size: 12px;
  text-align: left;
  color: #ffffff9e;
}
p.moreThanMaxBy span {
  color: #cc9ff8;
  float: right;
  font-size: 14px;
  background: #9f44f736;
  padding: 2px 10px;
  border-radius: 4px;
}
p.moreThanMaxBy {
  font-size: 13px;
  text-align: left;
  color: #ffffff9e;
  border-radius: 4px;
  margin-bottom: 0;
}
.presal-amount {
  height: 75px;
}
@keyframes vertical-shaking {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(-5px);
  }
  75% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes italic-shaking {
  0% {
    transform: skewY(0);
  }
  25% {
    transform: skewY(3deg);
  }
  50% {
    transform: skewY(-3deg);
  }
  75% {
    transform: skewY(3deg);
  }
  100% {
    transform: skewY(0);
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.what-is-ds-title h1 {
  font-family: "Sora";
  font-weight: 600;
  font-size: 32px;

  letter-spacing: -0.02em;
  text-transform: capitalize;

  background: linear-gradient(
    93.94deg,
    #e34038 1.3%,
    #e34038 33.26%,
    #e34038 74.34%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  flex: none;
  order: 0;
  flex-grow: 1;
}

.what-is-ds-title h6 {
  font-family: "Sora";
  font-size: 14px;

  display: flex;
  align-items: flex-end;
  text-align: right;

  color: #ced3df;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.ds-advantages {
  list-style-type: square;
}

.mb-ds {
  /* margin-bottom: 300px; */
}

@media (min-width: 992px) {
  .lg-45 {
    font-size: 45px !important;
  }
  .lg-hidden {
    display: none;
  }

  .text-gap {
    gap: 35px;
  }
  .h-lg-100 {
    height: 100% !important;
  }
}

@media (max-width: 992px) {
  .mb-ds {
    /* margin-bottom: 20px; */
  }

  .custom-live-section {
    background: url(../../../../assets/ds-mob.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .mh-100 {
    min-height: 100vw;
  }

  .res-ds-live {
    margin-top: 16vw;
    text-align: center;
    justify-content: start !important;
  }
}

@media (max-width: 575.98px) {
  .mw-100 {
    max-width: 100%;
  }
  .what-is-ds-title h1 {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .res-ds-live {
    margin-top: 2vw;
  }
}

.mt-40 {
  margin-top: 40px;
}
