.revenue-list {
  gap: 30px;
  color: #818a9e;
  list-style: none;
  padding: 0;
}

.revenue-bg {
  background: #3b3b3b;
  border-radius: 20px;
  text-align: center;
  margin: 24px auto;
}

.revenue-list li::before {
  content: "⊙ ";
  color: #818a9e;
  font-size: 16px;
}

.revenue-table tr {
  background-color: none;
}

.DistributionVesting .list-data-revenue > :not(caption) > * > * {
  background-color: #3b3b3b !important;
}

@media (max-width: 992px) and (min-width: 768px) {
  .revenue-list {
    gap: 12px;
  }
}

@media (max-width: 1380px) and (min-width: 1200px) {
  .claim-box {
    zoom: 0.9;
  }
}

.my-24-mx-auto {
  margin: 24px auto;
}

/* chart.js */

.chart-container {
  position: relative;
  margin: auto;
  height: 100%;
  width: 100%;
  background: #3b3b3b;
  border-radius: 20px;
  padding: 1rem 1.5rem;
}

.chart-container h1 {
  font-weight: 600;
  font-size: 1rem;
  color: #fcfcfd;
}

.chart-container p {
  font-weight: 600;
  font-size: 1.125rem;
  color: #fcfcfd;
}

/* claim.js */

.claim-box {
  padding: 42px 42px 32px;
}

.claim-box h1 {
  font-weight: 600;
  font-size: 32px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #fcfcfd;
}

.revenue-amount p {
  font-weight: 700;
  font-size: 24px;
  /* Theme Color/Primary */
  color: #ffffff;
}

.revenue-amount p:nth-child(2) {
  color: #ff4a41;
}
