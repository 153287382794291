.marketplaceFillter {
  background: #3b3b3b;
  border-radius: 16px;
  padding: 25px;
}
.CategoriesFillter .marketplaceFillter .CategoriesInput span {
  font-size: 14px;
}
.CategoriesFillter .marketplaceFillter .CategoriesInput span svg {
  width: 1em;
  height: 1em;
}
.FillterBySort {
  display: flex;
  float: right;
  position: relative;
}
.FillterBySort .sortDiv {
  margin-right: 15px;
}
.FillterBySort .sortDiv img.SortImg {
  height: 22px;
  width: 22px;
  margin-right: 5px;
}
.CategoriesFillter
  .PriceInput
  .bg-border-gradiant.contact-form-bg
  .form-control.form-control-solid {
  border: 1px solid #545454 !important;
  padding-left: 1rem;
}
.PriceInput label.basic-url {
  font-weight: 300;
  font-size: 14px;
  color: #a3a3a3;
}
.StatusRadio label {
  color: #a3a3a3;
  margin-bottom: -5px;
  margin-left: 0;
  margin-right: 0;
}
.StatusRadio label svg {
  color: #545454;
}
.StatusRadio .Mui-checked span svg {
  color: #e34038 !important;
}
.card-owner-name {
  word-break: break-all;
}
.FillterBySort .sortDiv.pointer a {
  color: #fff;
  opacity: 0.7;
}
.FillterBySort .sortDiv.pointer a:hover,
.FillterBySort .sortDiv.pointer a.active {
  opacity: 1;
}
.user-image img.profilePicture {
  width: 41px;
  height: 41px;
  border-radius: 50%;
  background: linear-gradient(
    96.27deg,
    #115743 12.79%,
    #4eb093 50.13%,
    #115743 107.19%
  );
}
img.verification-badge {
  width: 15px;
  height: 15px;
}
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
  display: none;
}
/* // Medium devices (tablets, less than 992px) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* // X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
}
