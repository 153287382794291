.admin {
  background: #2b2b2b;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}
html {
  position: relative;
  min-height: 100%;
  height: 100%;
}
a:focus {
  outline: none;
}
#wrapper {
  display: flex;
}
#wrapper #content-wrapper {
  background-color: #eef0f8;
  width: 100%;
  overflow-x: hidden;
}
#wrapper #content-wrapper #content .container-fluid {
  padding: 0 25px;
}
#wrapper #content-wrapper #content {
  flex: 1 0 auto;
}
.admin {
  font-size: 0.85rem;
  color: #5a5c69 !important;
}
.card.card-custom {
  -webkit-box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  border: 0;
}
.scroll-to-top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: none;
  width: 2.75rem;
  height: 2.75rem;
  text-align: center;
  color: #fff;
  background: rgba(90, 92, 105, 0.5);
  line-height: 46px;
}
.scroll-to-top:focus,
.scroll-to-top:hover {
  color: white;
}
.scroll-to-top:hover {
  background: #5a5c69;
}
.scroll-to-top i {
  font-weight: 800;
}
.text-xs {
  font-size: 0.7rem;
}
.text-lg {
  font-size: 1.2rem;
}
.icon-circle {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropdown .dropdown-menu {
  font-size: 0.85rem;
}
.dropdown .dropdown-menu .dropdown-header {
  font-weight: 800;
  font-size: 0.65rem;
  color: #b7b9cc;
}
.dropdown.no-arrow .dropdown-toggle::after {
  display: none;
}
.btn-circle {
  border-radius: 100%;
  height: 2.5rem;
  width: 2.5rem;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn-circle.btn-sm,
.btn-group-sm > .btn-circle.btn {
  height: 1.8rem;
  width: 1.8rem;
  font-size: 0.75rem;
}
.btn-circle.btn-lg,
.btn-group-lg > .btn-circle.btn {
  height: 3.5rem;
  width: 3.5rem;
  font-size: 1.35rem;
}
.btn-icon-split {
  padding: 0;
  overflow: hidden;
  display: inline-flex;
  align-items: stretch;
  justify-content: center;
}
.btn-icon-split .icon {
  background: rgba(0, 0, 0, 0.15);
  display: inline-block;
  padding: 0.375rem 0.75rem;
}
.btn-icon-split .text {
  display: inline-block;
  padding: 0.375rem 0.75rem;
}
.btn-icon-split.btn-sm .icon,
.btn-group-sm > .btn-icon-split.btn .icon {
  padding: 0.25rem 0.5rem;
}
.btn-icon-split.btn-sm .text,
.btn-group-sm > .btn-icon-split.btn .text {
  padding: 0.25rem 0.5rem;
}
.btn-icon-split.btn-lg .icon,
.btn-group-lg > .btn-icon-split.btn .icon {
  padding: 0.5rem 1rem;
}
.btn-icon-split.btn-lg .text,
.btn-group-lg > .btn-icon-split.btn .text {
  padding: 0.5rem 1rem;
}
.card .card-header .dropdown {
  line-height: 1;
}
.card .card-header .dropdown .dropdown-menu {
  line-height: 1.5;
}
.card .card-header[data-toggle="collapse"] {
  text-decoration: none;
  position: relative;
  padding: 0.75rem 3.25rem 0.75rem 1.25rem;
}
.card .card-header[data-toggle="collapse"]::after {
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 1.725rem;
  line-height: 51px;
  font-weight: 900;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  color: #d1d3e2;
}
.card .card-header[data-toggle="collapse"].collapsed {
  border-radius: 0.35rem;
}
.card .card-header[data-toggle="collapse"].collapsed::after {
  content: "\f105";
}
.chart-area {
  position: relative;
  height: 10rem;
  width: 100%;
}
@media (min-width: 768px) {
  .chart-area {
    height: 20rem;
  }
}
.chart-bar {
  position: relative;
  height: 10rem;
  width: 100%;
}
@media (min-width: 768px) {
  .chart-bar {
    height: 20rem;
  }
}
.chart-pie {
  position: relative;
  height: 15rem;
  width: 100%;
}
@media (min-width: 768px) {
  .chart-pie {
    height: calc(20rem - 43px) !important;
  }
}
.bg-login-image {
  background: url("https://source.unsplash.com/K4mSJ7kc0As/600x800");
  background-position: center;
  background-size: cover;
}
.bg-register-image {
  background: url("https://source.unsplash.com/Mv9hjnEUHR4/600x800");
  background-position: center;
  background-size: cover;
}
.bg-password-image {
  background: url("https://source.unsplash.com/oWTW-jNGl9I/600x800");
  background-position: center;
  background-size: cover;
}
form.user .custom-checkbox.small label {
  line-height: 1.5rem;
}
form.user .form-control-user {
  font-size: 0.8rem;
  border-radius: 10rem;
  padding: 1.5rem 1rem;
}
form.user .btn-user {
  font-size: 0.8rem;
  border-radius: 10rem;
  padding: 0.75rem 1rem;
}
.btn-google {
  color: #fff;
  background-color: #ea4335;
  border-color: #fff;
}
.btn-google:hover {
  color: #fff;
  background-color: #e12717;
  border-color: #e6e6e6;
}
.btn-google:focus,
.btn-google.focus {
  color: #fff;
  background-color: #e12717;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-google.disabled,
.btn-google:disabled {
  color: #fff;
  background-color: #ea4335;
  border-color: #fff;
}
.btn-google:not(:disabled):not(.disabled):active,
.btn-google:not(:disabled):not(.disabled).active,
.show > .btn-google.dropdown-toggle {
  color: #fff;
  background-color: #d62516;
  border-color: #dfdfdf;
}
.btn-google:not(:disabled):not(.disabled):active:focus,
.btn-google:not(:disabled):not(.disabled).active:focus,
.show > .btn-google.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-facebook {
  color: #fff;
  background-color: #3b5998;
  border-color: #fff;
}
.btn-facebook:hover {
  color: #fff;
  background-color: #30497c;
  border-color: #e6e6e6;
}
.btn-facebook:focus,
.btn-facebook.focus {
  color: #fff;
  background-color: #30497c;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-facebook.disabled,
.btn-facebook:disabled {
  color: #fff;
  background-color: #3b5998;
  border-color: #fff;
}
.btn-facebook:not(:disabled):not(.disabled):active,
.btn-facebook:not(:disabled):not(.disabled).active,
.show > .btn-facebook.dropdown-toggle {
  color: #fff;
  background-color: #2d4373;
  border-color: #dfdfdf;
}
.btn-facebook:not(:disabled):not(.disabled):active:focus,
.btn-facebook:not(:disabled):not(.disabled).active:focus,
.show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.error {
  color: #5a5c69;
  font-size: 7rem;
  position: relative;
  line-height: 1;
  width: 12.5rem;
}
.error:after {
  content: attr(data-text);
  position: absolute;
  left: 2px;
  text-shadow: -1px 0 #e74a3b;
  top: 0;
  color: #5a5c69;
  background: #f8f9fc;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 2s infinite linear alternate-reverse;
}
.error:before {
  content: attr(data-text);
  position: absolute;
  left: -2px;
  text-shadow: 1px 0 #4e73df;
  top: 0;
  color: #5a5c69;
  background: #f8f9fc;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim-2 3s infinite linear alternate-reverse;
}
footer.sticky-footer {
  padding: 2rem 0;
  flex-shrink: 0;
}
footer.sticky-footer .copyright {
  line-height: 1;
  font-size: 0.8rem;
}
body.sidebar-toggled footer.sticky-footer {
  width: 100%;
}
.dropdown-profile.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.85rem;
  color: #858796;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
}
.dropdown-profile.dropdown-menu .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.45rem 1.5rem !important;
  clear: both;
  font-weight: 400;
  color: #3a3b45;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-profile.dropdown-menu .dropdown-item:hover {
  background: #eeeeee73;
}
.text-gray-400 {
  color: #bbbbbf !important;
}
/* iframe {
  display: none;
} */
