.WriteAboutUs h1 {
  background: linear-gradient(
    92.1deg,
    #e34038 -12.35%,
    #e34038 30.1%,
    #e34038 104.64%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 600;
  font-size: 45px;
  line-height: 57px;
  text-align: center;
  width: fit-content;
  margin: 0 auto 2rem;
}

.WriteAboutUs {
  background: #3b3b3b;
  border-radius: 28px;
  padding: 3rem 3rem;
  border: 2px solid rgb(255 255 255 / 11%);
  margin-bottom: 4rem;
}
.WriteAboutUs-bg {
  background-image: url(../../../assets/home&token/How-write-about-us.svg);
  background-repeat: no-repeat;
  background-position: 50% 10px;
  background-size: contain;
  background-color: #2b2b2b;
  padding-top: 0rem !important;
  padding-bottom: 3rem;
  margin-top: -1rem;
}
.m-all-auto .col-md-auto {
  margin: 0 auto;
}
.WriteAboutUs img {
  height: 38px;
}
@media (max-width: 991.98px) {
  .WriteAboutUs-bg {
    background-position: 50% 78%;
    background-size: 177%;
    background-color: #2b2b2b;
    padding-top: 0rem !important;
    padding-bottom: 3rem;
    margin-top: -1rem;
  }
  .WriteAboutUs {
    padding: 3rem 2rem 2rem;
  }
  .WriteAboutUs h1 {
    font-size: 23px;
    line-height: 20px;
  }
  .WriteAboutUs img {
    max-height: 23px;
    margin-bottom: 15px;
    width: fit-content;
  }
}
