.wallet-connect-text h1 {
  font-family: "Sora";
  font-weight: 700;
  font-size: 65px;
  line-height: 82px;
  color: #ffffff;
  text-align: left;
}

.wallet-connect-text span {
  padding: 16px 24px;
  /* background: linear-gradient(
    96.27deg,
    #f85e5b 12.79%,
    #886e6b 50.13%,
    #ff786a 107.19%
  ); */
  background: linear-gradient(
    96.27deg,
    #4eb093 12.79%,
    #115743 50.13%,
    #4eb093 107.19%
  );
  border-radius: 57px;
  font-family: "Sora";
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #fcfcfd;
  width: 303px;
  cursor: pointer;
}

.disc-wallet-row {
  margin: 5vh auto 10vh;
}

.wallet-connect-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 29px;
}
body.ConnectToYourWallet.backgroundSlide1 {
  background-image: url(../../../assets/bg77.png);
}
body.ConnectToYourWallet.backgroundSlide2 {
  background-image: url(../../../assets/bg9.png);
}
body.ConnectToYourWallet.backgroundSlide3 {
  background-image: url(../../../assets/bg67.png);
}
body.ConnectToYourWallet.backgroundSlide4 {
  background-image: url(../../../assets/bg99.png);
}
body.ConnectToYourWallet.backgroundSlide5 {
  background-image: url(../../../assets/bg10.png);
}

body.UpdateNetWorkPage.backgroundSlide1 {
  background-image: url(../../../assets/bg6.png);
}
body.UpdateNetWorkPage.backgroundSlide2 {
  background-image: url(../../../assets/bg4.png);
}
body.UpdateNetWorkPage.backgroundSlide3 {
  background-image: url(../../../assets/bg67.png);
}
body.UpdateNetWorkPage.backgroundSlide4 {
  background-image: url(../../../assets/bg3.png);
}

body.WaitingForSignature.backgroundSlide1 {
  background-image: url(../../../assets/bg5.png);
}
body.WaitingForSignature.backgroundSlide2 {
  background-image: url(../../../assets/bg8.png);
}
body.WaitingForSignature.backgroundSlide3 {
  background-image: url(../../../assets/bg9.png);
}
body.WaitingForSignature.backgroundSlide4 {
  background-image: url(../../../assets/bg2.png);
}

@media (min-width: 768px) {
  .disc-wallet-row {
    height: calc(100vh - 100px - 190px);
  }
}

@media (max-width: 1200px) {
  .wallet-connect-text h1 {
    font-size: 48px;
    line-height: 60px;
  }
  .wallet-connect-text span {
    font-size: 16px;
    line-height: 16px;
  }
}

@media (max-width: 992px) {
  .disc-wallet-row {
    justify-content: space-between;
  }
  .wallet-connect-text h1 {
    font-size: 32px;
    line-height: 40px;
    width: 100%;
    text-align: center;
  }
  .wallet-connect-text span {
    font-size: 14px;
    width: 100%;
  }
}

@media (max-width: 576px) {
  .wallet-connect-text h1 {
    font-size: 32px;
    line-height: 40px;
  }
  .wallet-connect-text span {
    width: 100%;
  }
}
