.nftOfferModal .modal-content {
  background: #3b3b3b;
  border-radius: 30px;
  padding: 2rem 1rem 1rem !important;
}

.nftOfferModal h4 {
  font-weight: 600;
  font-size: 32px;
  letter-spacing: -0.01em;
  color: #ffffff;
  margin: 0;
}

.nftOfferModal p {
  color: #ced3df;
}

.nftOfferModal span {
  font-weight: 600;
  letter-spacing: -0.01em;
  color: #ffffff;
  width: fit-content;
}

.nftOfferModal ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
svg.svgOption {
  width: 100%;
  aspect-ratio: 1;
  /* object-fit:  */
  height: 100%;
}
.svgOption pattern image {
  height: 100%;
  display: block;
  object-fit: cover;
  width: auto;
}

.edit-land-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.resale-form {
  background-color: #3b3b3b;
  border-radius: 16px;
  padding: 3rem 1rem;
  margin: 1rem 0;
}
